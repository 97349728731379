.cn_pp1
{
    position: absolute;
    z-index:1;
    background-color: #fff;
    top:3%;
    left:25%;
    font-size: 0.9vw;
    font-weight: bold;
    border-radius: 5px;
    width:53%;
    height: 90vh;
    overflow-y: auto;
    overflow-x: auto;
    /* border-radius: 10px; */
    /* border:1px solid black;+ */

}

/* .MainPopup{
    width: 100%;
    height: 100%;
    background-color: blue;
} */


.cn_pp1 textarea
{
    width: 100%;
    height: 100%;
    outline: 0;
    border-radius: 5px;
    /* border: 1px solid black; */
    background: inherit;
}


.cn_pp1_inner
{
    height:82vh;
    min-height: 400px;
    width:100%;
    min-width: 600px;

    padding:10px;
    padding-left: 30px;
    padding-right: 30px;


    /* border:1px solid red; */
}

.cn_download_file
{
    margin-bottom: 1%;
    display:flex;
    justify-content: space-between;
    /* border:1px solid black; */
}

.cn_download_file font
{
    font-size: 1vw;
}

.cn_file_img
{
    margin-bottom: 7%;
    width:50%;
    display: flex;
    justify-content: space-between;
    font-size:0.8vw;
    color: gray;
    border-bottom:1px solid gray;
}
.cn_heading_named_text
{
    /* border:1px solid black; */
}
.cn_textarea_1
{
height:25%;
/* border:1px solid black; */
margin-bottom: 7%;
}


.cn_heading_named_recipients
{
    /* border:1px solid black; */
}
.cn_textarea_2
{
    height:50%;
    overflow-y: auto;
    border-radius: 4px;
    /* border:1px solid black; */
}

.cn_textarea_2 table
{
    padding: 0;
    margin: 0;
}


@media screen and (max-width:900px) {

    .cn_pp1
{
 font-size: 12px;
 
}

.cn_file_img
{
    font-size: 10px;
}

}

