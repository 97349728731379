/* Common CSS */

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(5deg);
  }

  70% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/* Variables */
:root {
  --proctur-blue: #109CF1;
  --proctur-black: #000000;
}

/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  width: 13%;
  font-family: 'Lato', sans-serif;
  height: calc(100%);
  max-height: 100%;
  position: fixed;
  bottom: 0;
  left: 0px;
  background-color: #fff0;
  z-index: 112 !important;
}

/*.sidebar-wrapper:hover {
    width: 460px;
  }
  */
.sidebar-wrapper ul {
  list-style-type: none;
  padding-top: 20px;
  padding-bottom: 60px;
  text-indent: 15px;
  width: calc(100%);
  position: absolute;
  left: 0;
  top: 50px;
}

.sidebar-wrapper a {
  text-decoration: none;
}
.sidebar-wrapper a:hover{
  color: #fff;
}

.sidebar-wrapper .icons li img {
  width: 20px;
  height: auto;
  margin-right: 6px;
}

.sidebar-wrapper .icons li p {
  margin-block-start: 0.25em;
  line-height: 0.25em;
  font-size: 13px;
  color: #f2ecf8;
  display: inline;
  font-weight: 300;
}

.sidebar-wrapper .icons li p:hover{
  color: #f2ecf8;

}

.sidebar-toggle-button {
  position: fixed;
  display: none;
  display: inline;
  top: 8px;
  left: 0px;
  height: 31px;
  line-height: 15px;
  background: #1B2433;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 2px 8px;
  z-index: 10;
}

.sidebar-toggle-button>i {
  float: initial;
  text-indent: 0px;
  position: relative;
  top: -4px;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  direction: rtl;
  /*overflow-y: scroll;*/
  /*  background-image: linear-gradient(to bottom, #8e62f5 , #2f46f6);*/
  overflow-x: visible;
}

.power {
  width: calc(13%);
  color: #fff;
  position: fixed;
  bottom: 0em;
  padding: 10px 0;
  text-align: center;
  font-size: 10px;
  font-weight: 300;
  left: 10px;
  z-index: 10;
  background-color: #1B2433;
  margin-bottom: 0rem;
}

.sidebar-content>p .proctur40 {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}



/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  width: calc(100% - 11%);
  margin-left: 11%;
  margin-top: 45px;
  margin-bottom: -6px;
  background: #f9f9f9 url(../../assets/img/images/bg_group.svg) no-repeat;
  background-position: bottom right;
  height: calc(100vh - 45px);
}

/* .page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  width: calc(100% - 13%);
  margin-left: 13%;
  margin-top: 60px;
  margin-bottom: -6px;
  background: #f9f9f9 url(../../assets/img/images/bg_group.svg) no-repeat;
  background-position: bottom right;
  height: calc(100vh - 60px);
} */



.icons {
  padding-inline-start: 0px;
}


.icons li {
  padding: 6px 5px 6px 5px;
  direction: ltr;
  /*text-align: center;*/
}

.iconSidebar {
  /*background-image: linear-gradient(90deg, rgba(16,156,241,0.05675773727459732) 9%, rgba(16,156,241,1) 46%);*/
  background: linear-gradient(270deg, var(--proctur-blue) 55.09%, rgba(16, 156, 241, 0) 113.05%);
  border-radius: 10px 0px 0px 10px;
}


i {
  color: #fff;
  float: right;
  padding-top: 6px;
  font-size: 12px;
  font-weight: bold;
  text-indent: 20px;
}

.dropdown {
  /* position: relative;
    display: inline-block;*/
}

.dropdown-content {
  left: 49%;
  top: 0px;
  display: none;
  position: absolute;
  background-color: #1B2433;
  min-width: 210px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  padding: 12px 10px;
  text-decoration: none;
  display: block;
  margin-block-start: 0em;
  font-size: 13px;
  color: #f2ecf8;
  font-weight: 300;
}

.dropdown-content a:hover {
  background: linear-gradient(270deg, var(--proctur-blue) 55.09%, rgba(16, 156, 241, 0) 113.05%);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.list,
ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-wrapper .sidebar-brand {
  width: calc(13%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid #81868e;
  position: fixed;
  left: 0px;
  background-color: #1B2433;
  z-index: 10;
}

/* .sidebar-wrapper .sidebar-brand > a {
    font-weight:500;
    flex-grow: 1;
    color: #fff;
    font-size: 14px;
    padding: 10px;
  } */

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}

.paneldd {
  position: fixed;
  background: #1B2433;
  width: 13%;
  height: 100vh;
}

/*Scroll Bar*/
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--proctur-blue);

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--proctur-blue);
}

.fa-angle-right:before {
  position: absolute;
  left: 137px;
}

/* Header */
header {
  height: 60px;
  position: fixed;
  right: 0%;
  width: calc(100% - 13%);
  border-bottom: 1px solid #EBEFF2;
  z-index: 1;
  background: #fff;
}

.header {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-left {
  height: 60px;
  align-items: center;
  display: flex;
  padding-left: 20px;
}

.header-left #show-sidebar {
  padding-right: 10px;
  cursor: pointer;
  display: none;
}

.header-left>p {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */
  margin-bottom: 0px;
  letter-spacing: 0.01em;

  color: #334D6E;
}

.header-right {
  height: 60px;
  align-items: center;
  display: flex;
  padding-right: 25px;
}

.header-right img {
  width: auto;
  height: 20px;
}

.m20 {
  margin-left: 28px;
}

.head_img {
  width: 28px;
  height: auto;
  cursor: pointer;
}

/* 
  .header-input{
    padding: 10px 20px;
    font-size: 12px;
    padding: 10px 20px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 10px;
    font-weight: 600;
    width: 700px;
    color: #61758E;
    background-image: url(img/Vector.svg);
    background-repeat: no-repeat;
    background-position:left center;
  } */
.header-input::placeholder {
  padding-left: 10px;
}

/*main*/
* {
  margin: 0px;
  padding: 0px;
  font-family: 'Lato', sans-serif;
}

button {
  border: none;
  outline: none;
}

button:focus,
button:hover {
  outline: none;
  cursor: pointer;
}

.display {
  display: flex;
  justify-content: space-between;
}
.align-s{
  align-items: flex-start;
}
.flex {
  display: flex;
}

.flex-a {
  display: flex;
  align-items: center;
}

.right {
  float: right;
}


a:hover {
  text-decoration: none;
}

.blue_button {
  background: #0097F9;
  border-radius: 2px;
  font-weight: 600;
  height: 30px;
  padding: 0px 8px;
  font-size: 13px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #FFFFFF;
  padding: 0px 16px;
}

.white_button {
  border-radius: 2px;
  height: 30px;
  border: 1px solid #939393;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */
  padding: 0px 16px;
  letter-spacing: 0.01em;

  color: #636363;
  background: #ffffff;
}

.red_button {
  border-radius: 2px;
  height: 30px;
  border: 1px solid #ff0000;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */
  padding: 0px 16px;
  letter-spacing: 0.01em;
  white-space: nowrap;
  color: #ff0000;
  background: #ffffff;
}

.checkbox {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  cursor: pointer;
  font-size: 2rem;
}

.checkbox__input input {
  opacity: 0;
  width: 0em;
  height: 0em;
}

.checkbox__control {
  display: inline-grid;
  width: 18px;
  height: 18px;
  border-radius: 0.1em;
  border: 1px solid #BBB2B2;

}

.checkbox__input {
  display: grid;
  grid-template-areas: "checkbox";
}

.checkbox__control svg {
  color: #fff;
  background-color: var(--proctur-blue);
  transition: transform 0.1s ease-in 25ms;
  transform: scale(0);
  stroke-width: 4;
  border: none;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  padding: 2px;
  transform-origin: bottom left;
}

.checkbox__input input:checked+.checkbox__control svg {
  transform: scale(1);
}

.checkbox__input input:checked+.checkbox__control {
  border: none;
}


/*radio*/
.form-radio {
  height: 16px;
  width: 16px;
  margin-right: 6px;
}



.select-wrapper {
  width: 200px;
  position: relative;
  margin-right: 80px;
}

.select-wrapper:after {
  /*  content: url(img/select-drop.svg);
    width: 0;
    height: 0;
    position: absolute;
    top: 0px;
    right: 10px; */
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #666;
  position: absolute;
  right: 8px;
  top: 16px;
  pointer-events: none;
}

select {
  width: 100%;
  -webkit-appearance: none;
}

select option[value="ind"] {
  background: url(../../assets/img/images/Ind.svg) no-repeat;
  background-position: center left;
}

input[type=date]::-webkit-calendar-picker-indicator {
  background: url(../../assets/img/images/Calendar.svg) no-repeat;
  opacity: 1;
  display: block;
  width: 20px;
  height: 20px;
}

.f-wrap {
  flex-wrap: wrap;
}

.center-align {
  text-align: center;
}

.mr16 {
  margin-right: 10px;
}

.ml16 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.flex-inline {
  display: inline-flex;
}

.page-content {
  padding: 18px 24px 0px;
}

.mt10 {
  margin-bottom: 10px;
}

.modal-header,
.modal-footer {
  border: none;
  padding: 16px;
}

.modal-body {
  padding: 0px 16px 0px 16px;
}

.modal-header .line_purple {
  margin-bottom: 0px;
}

.modal-header {
  padding-bottom: 0px;
}

@media (min-width: 576px) {

  .modal-dialog {
    max-width: 100%;
  }
}

.modal-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #334D6E;
}

.mb-0 {
  margin-bottom: 0px;
}

.pointer {
  cursor: pointer;
}

.mt-32 {
  margin-top: 32px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-36 {
  margin-top: 36px;
}

/* Common Responsive CSS */
@media (max-width: 850px) {

  .sidebar-wrapper {
    display: none;
  }

  .paneldd,
  #sidebar {
    display: none;
    width: 60%;
    z-index: 40;
  }

  .page-wrapper .page-content {
    margin-left: 0;
    width: 100%;
    margin-top: 80px;
    height: auto;
    padding: 10px;
  }

  .sidebar-wrapper .sidebar-brand {
    width: calc(60%);
  }

  header {
    height: auto;
    width: calc(100%);
  }

  .header {
    display: block;
  }

  .header>div {
    height: auto;
  }

  .header-left {
    margin-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .header-right {
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: end;
    padding-right: 10px;
  }

  .m20 {
    margin-left: 10px;
  }
}


/* Profile Page */
.progress_bar-outter {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 24px;
}

.progress_bar {
  display: flex;
}

.opacity-5 {
  opacity: 0.5;
}

.progress_bar-outter>div:last-child {
  margin-left: auto;
  text-align: right;
}

.progress_bar-outter>div:last-child>p {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */
  margin-bottom: 0px;
  letter-spacing: 0.01em;
  margin-top: 24px;
  color: #636363;
}

.progress_bar>div>span {
  font-weight: 600;
  /*   font-size: 16px; */
  line-height: 22px;
  font-size: 15px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #636363;

}

/* This class is only for the current selected tab */
.progress_bar>div.current>span {
  color: var(--proctur-blue);

}

.progress_bar>div>div {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.progress_bar>div>div>button {
  width: 20px;
  border-radius: 50%;
  height: 20px;
  background: #F3F2F2;
}

.progress_bar>div.selected>div>button {
  background: #D1EDFF;
  border: 2px solid #378FFB;
}

.progress_bar>div>div>div {
  height: 2px;
  width: 9.5vw;
  background: #F3F3F3;
}


.form-section {
  height: calc(100vh - (116px + 60px + 30px));
  overflow-y: auto;
}

.modal .form-section {
  height: auto;
}

.modal .form-section>div {
  width: 100%;
}

.modal .form-section>div .select-wrapper {
  width: 100%;
}

.modal .form-section>div {
  padding: 10px 0px;
}

.form-section::-webkit-scrollbar {
  display: none;
}

.form-section>div {
  width: 848px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 16px 24px;
  margin: 10px auto 0px;
}

.form-section>div .heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #334D6E;
  margin-bottom: 0px;
}

.form-section>div .heading>span {
  background: #ED8307;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */
  margin-left: 10px;
  letter-spacing: 0.01em;
  display: inline-block;
  color: #FFFFFF;
  padding: 0px 8px;
}

.form-section>div select.expandale-input>option[value=""] {
  color: #696969;
}

.form-section>div select.expandale-input select>option {
  color: var(--proctur-black);
}

.form-section>div .line_purple,
.line_purple {
  width: 37px;
  height: 4px;
  margin-top: 4px;
  margin-bottom: 8px;
  background: rgba(110, 98, 255, 0.74);
}

.form-section>div .expandale-input {
  min-height: 23px;
  /*   max-height: 90px; */
  border: none;
  border-bottom: 1px solid #E0E0E0;
  font-weight: 400;
  font-size: 13px;
  /* line-height: 128.91%; */
  line-height: 269.91%;
  /* identical to box height, or 17px */
  letter-spacing: 0.01em;
  margin-bottom: 0px;
  width: 100%;
  color: var(--proctur-black);
}

.form-section>div .expandale-input.w-200 {
  width: 200px;
  margin-right: 80px;

}

.form-section>div .w-14 {
  width: 215px;
  margin-left: 20px;
}

.form-section>div .expandale-input.w-20 {
  width: 80px;
}

.form-section>div .select-wrapper.w-20 {
  width: 80px;
  margin-right: 20px;
}

.form-section>div .expandale-input.w-450,
.select-wrapper.w-450 {
  width: 440px;
}

.form-section>div .expandale-input.w-300 {
  width: 300px;
}

.form-section>div .expandale-input:focus {
  outline: none;
}

.mr-70 {
  margin-right: 70px;
}

.mr-50 {
  margin-right: 50px;
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  font-weight: 400;
  font-size: 13px;
  line-height: 128.91%;
  letter-spacing: 0.01em;
  color: #696969;
}

.form-section>div p.data-info {
  font-weight: 400;
  font-size: 10px;
  line-height: 128.91%;
  /* identical to box height, or 13px */
  letter-spacing: 0.01em;
  margin-bottom: 8px;
  color: #AFAFAF;
}

.form-section>div label {
  font-weight: 400;
  font-size: 16px;
  line-height: 128.91%;
  /* identical to box height, or 21px */
  letter-spacing: 0.01em;
  color: #000000;
  display: block;
}

.form-section>div label img,
.form-section>div label embed {
  margin-right: 8px;
}

.form-section>div label>span {
  color: #F80606;
  margin-left: 4px;
}

.form-section>div label.checkbox>span {
  margin-left: 0px;
}

.flex-p {
  display: flex;
}

.flex-p>a {
  margin-left: auto;
  font-weight: 400;
  font-size: 13px;
  line-height: 128.91%;
  /* identical to box height, or 17px */
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #109CF1;
}

.flex-p1 {
  border-bottom: 1px solid #E0E0E0;
  width: 440px;
  display: inline-flex;
  align-items: center;
}

.flex-p1>a {
  font-weight: 400;
  font-size: 13px;
  line-height: 128.91%;
  /* identical to box height, or 17px */
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #109CF1;

}

.form-section>div .flex-p1 .select-wrapper {
  width: 80px;
  margin-right: 0px;
  font-weight: 400;
  font-size: 11px;
  line-height: 128.91%;
  /* or 14px */
  letter-spacing: 0.01em;
  color: #000000;

}

.form-section>div .flex-p1 .select-wrapper .india {
  background: url(../../assets/img/images/Ind.svg) no-repeat;
  background-position: center left;
  padding-left: 20px;
}

.form-section>div .flex-p1 .select-wrapper .expandale-input {
  width: 100%;

}

.form-section>div .flex-p1 .expandale-input {
  border: none;
}

.form-section>div .flex-p1>button {
  width: 1px;
  height: 16px;
  background: #C4C4C4;
  margin: 0px 8px 0px;
}

.form-section>div .flex-p1 .expandale-input.w-450 {
  width: 100%;
}

.flex-inline {
  font-weight: 400;
  font-size: 13px;
  line-height: 128.91%;
  /* identical to box height, or 17px */
  letter-spacing: 0.01em;
  color: #000000;


}

input[type="time"]::-webkit-calendar-picker-indicator {
  width: 20px;
  height: 20px;
  background: url("../../assets/img/clock.png");
}

.flex-inline:not(:first-of-type) {
  margin-left: 20px;
}

.flex-inline span {
  margin-right: 4px;
}

.diff-position {
  position: relative;
}

.diff-position a {
  position: absolute;
  top: 10px;
  right: 16px;
}

.display.f-wrap>div {
  margin: 0px 0px 32px;
  width: 30%;
}

.hidden1 {
  visibility: hidden;
}

.flex>.select-wrapper {
  width: 80px;
  margin-right: 80px;

}

.form-section>div .display .select-wrapper {
  width: 100%;
}

.form-section>div .display .expandale-input.w-200 {
  width: 100%;
}

.form-section>div .display .flex .select-wrapper {
  width: 25%;
  margin-right: 0px;
  border-bottom: 1px solid #E0E0E0;

}

.form-section>div .display .flex .select-wrapper select {
  border: none;
}

.form-section>div .display .flex .expandale-input {
  width: 70%;
}

.tags {
  margin-top: 24px;

}

.tags>button {
  background: #DBF1FF;
  border-radius: 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 128.91%;
  /* identical to box height, or 17px */
  padding: 4px 8px;
  margin-right: 16px;
  margin-bottom: 8px;
  letter-spacing: 0.01em;
  color: #000000;
}

.tags>button>img {
  height: 20px;
  width: 20px;
}

.tags-img {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}

.tags-img>div {
  display: flex;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  align-items: flex-start;
}

.tags-img>div>img:first-of-type {
  height: 60px;
  width: auto;
}

.tags-img>div>img:last-of-type {
  margin-top: -10px;
  margin-left: -16px;
}

.form-section>div .expandale-input.choose {
  font-weight: 400;
  font-size: 13px;
  /* identical to box height, or 17px */
  letter-spacing: 0.01em;
  color: #696969;

}

.scroll-solve {
  align-self: center;
}

.ml-8 {
  margin-left: 8px;
}

.m-24 {
  margin-bottom: 24px;
}

.box-section {
  display: flex;
  width: 848px;
  flex-wrap: wrap;
  height: calc(100vh - (116px + 60px + 90px));
  margin: 10px auto;
  overflow-y: auto;
  align-content: flex-start
}

.box-section::-webkit-scrollbar,
.box-view3::-webkit-scrollbar {
  display: none;
}

.box-section>div {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 275px;
  padding: 16px;
  margin-bottom: 10px;
}

.box-section>div:not(:nth-child(3n)) {
  margin-right: 10px;
}

.box-section1,
.box-educator1 {
  display: flex;
  justify-content: space-between;
  width: 848px;
  align-items: center;
  margin: 10px auto;
}

.box-section1>div {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #636363;
}

.box-section>div label {
  font-weight: 400;
  font-size: 13px;
  line-height: 128.91%;
  /* identical to box height, or 17px */
  letter-spacing: 0.01em;
  margin-bottom: 0px;
  color: #AFAFAF;
}

.box-section>div p {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  width: 70%;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #334D6E;
}

.line-sep {
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
  background: #E4E4E4;
}

.switch1 {
  position: relative;
  display: inline-block;
  height: 14px;
  margin-bottom: 0px;
  width: 34px;
}

.switch1 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider1:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -5px;
  bottom: -2.5px;
  background: #BDBABA;
  box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider1 {
  background-color: #109CF180;
}

input:checked+.slider1:before {
  background: #109CF1;
}


input:checked+.slider1:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider1.round {
  border-radius: 34px;
}

.slider1.round:before {
  border-radius: 50%;
}

.box-section>div span,
.box-educator>div .flex-a span {
  font-weight: 400;
  font-size: 10px;
  line-height: 128.91%;
  /* identical to box height, or 13px */
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  padding-right: 10px;
  color: #AFAFAF;

}

.editbutton {
  font-weight: 600;
  font-size: 10px;
  line-height: 25px;
  /* identical to box height */
  padding: 0px 8px;
  background-color: #fff;
  letter-spacing: 0.01em;
  border: 1px solid #A2ADA9;
  box-sizing: border-box;
  border-radius: 10px;
  color: #A2ADA9;
}

.deletebutton {
  font-weight: 600;
  font-size: 10px;
  line-height: 25px;
  background-color: #fff;
  /* identical to box height */
  border: 1px solid #EF1111;
  box-sizing: border-box;
  border-radius: 10px;
  letter-spacing: 0.01em;
  padding: 0px 8px;
  color: #EF1111;

}

.flex-p1 embed {
  height: 22px;
  width: 22px;
}

.time {
  background: url(../../assets/img/images/time.svg) no-repeat;
  background-position: right center;
}


.form-section>div.button-section {
  background: transparent;
  display: flex;
  justify-content: space-between;
  padding-right: 0px;
}

.hidden {
  display: none;
}

/* Educator section */
.box-educator1 {
  display: flex;
  justify-content: space-between;
  width: 848px;
  align-items: center;
  margin: 10px auto;
}

.search {
  background: url(../../assets/img/search.svg) #FFFFFF no-repeat;
  background-position: 96% center;
  border-radius: 4px;
  height: 30px;
  border: none;
  outline: none;
  width: 180px;
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
  /* identical to box height */
  color: var(--proctur-black);
  opacity: 0.8;
  padding-left: 8px;
}

.search::placeholder {
  color: #323941;
}

.filter {
  background-color: transparent;
  height: 30px;
}

.tags-e {
  height: 30px;
  font-weight: 400;
  font-size: 13px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #334D6E;
  line-height: 30px;
  padding: 0px 16px;
  background: #EAE9E9;
  margin-right: 4px;
  border-radius: 15px;
}

.tags-e.selected-tag {
  font-weight: 600;
  /* identical to box height */
  background: #109CF1;
  color: #FFFFFF;
}

.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext1 {
  display: none;
  background: #FFFFFF;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  min-width: 310px;
  letter-spacing: 0.01em;
  color: #334D6E;
  position: absolute;
  z-index: 1;
}

.tooltip1 .tooltiptext1.large {
  min-width: 630px;
}

.tooltip1:hover .tooltiptext1 {
  display: initial;
}

.box-educator {
  display: flex;
  width: 848px;
  flex-wrap: wrap;
  height: calc(100vh - (116px + 60px + 90px));
  margin: 10px auto;
  overflow-y: auto;
  align-content: flex-start;
}

.box-educator::-webkit-scrollbar {
  display: none;
}

.box-educator>div {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 16px;
  width: calc(50% - 5px);
  margin-bottom: 10px;
}

.box-educator>div:not(:nth-child(2n)) {
  margin-right: 10px;
}

.profile_pic {
  width: 57px;
  margin-right: 16px;
  height: 57px;
  border-radius: 50%;
}

.name-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.name-div p {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.01em;
  margin-bottom: 4px;
  color: #334D6E;

}

.name-div span {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #CACBCC;

}

.proctur-tag {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */
  padding: 0px 6px;
  margin-bottom: 0px;
  letter-spacing: 0.01em;
  background: #8DEF7D4D;
  border-radius: 5px;
  color: #8C8C8C;
  margin-top: -10px;
  margin-right: -10px;
}

.mb-8 {
  margin-bottom: 8px;
}

.ml-a {
  margin-left: auto;
  display: flex;
}

.ml1-2 {
  margin-left: 3.1vw;
}

.added-by {
  margin: 0px 0px 8px;
}

.added-by>p:first-of-type {
  font-weight: 400;
  font-size: 13px;
  line-height: 128.91%;
  /* identical to box height, or 17px */
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  color: #AFAFAF;
}

.added-by>p:last-of-type {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #334D6E;

}

.line-v-grey {
  background: #F4F4F4;
  height: 1px;
  width: 100%;
  margin-bottom: 16px;
}

.viewbutton {
  font-weight: 600;
  font-size: 10px;
  line-height: 25px;
  /* identical to box height */
  padding: 0px 8px;
  background-color: #fff;
  letter-spacing: 0.01em;
  border: 1px solid #1A81D4;
  box-sizing: border-box;
  border-radius: 10px;
  color: #1A81D4;
  height: 25px;
}

.unlinkbutton {
  font-weight: 600;
  font-size: 10px;
  line-height: 25px;
  /* identical to box height */
  border: 1px solid #EF1111;
  box-sizing: border-box;
  border-radius: 10px;
  letter-spacing: 0.01em;
  padding: 0px 8px;
  background-color: #fff;
  color: #EF1111;
}

.movebutton {
  font-weight: 600;
  font-size: 10px;
  line-height: 25px;
  /* identical to box height */
  border: 1px solid #1451ED;
  box-sizing: border-box;
  border-radius: 10px;
  letter-spacing: 0.01em;
  padding: 0px 8px;
  background-color: #fff;
  color: #1451ED;
  ;
}

.acceptbutton {
  padding: 0px 8px;
  background-color: #fff;
  font-weight: 600;
  font-size: 10px;
  line-height: 25px;
  /* identical to box height */
  border: 1px solid #B414ED;
  box-sizing: border-box;
  border-radius: 10px;
  letter-spacing: 0.01em;
  color: #B414ED;
}

.rejectbutton {
  padding: 0px 8px;
  background-color: #fff;
  border: 1px solid #EC1515;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 600;
  font-size: 10px;
  line-height: 25px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #EC1515;

}


.red-para {
  font-weight: 400;
  font-size: 13px;
  line-height: 128.91%;
  /* or 17px */
  text-align: center;
  color: #ED0F0F;
}

@media (min-width: 576px) {

  #unlinkModal .modal-dialog,
  #deleteModal .modal-dialog {
    width: 300px;
  }

  #filterModal .modal-dialog {
    width: 500px;
  }

  #changeModal .modal-dialog {
    width: 400px;
  }
}

#unlinkModal embed,
#deleteModal embed {
  margin-top: 16px;
}

#unlinkModal button,
#deleteModal button {
  width: calc(50% - 8px);
}

.sort-div {
  position: absolute;
  width: 110px;
  z-index: 50;
  display: none;
  background-color: #fff;
}

.mystyle {
  display: block;
}

.sort-div>div {
  padding: 16px;
  border-bottom: 1px solid #f4f4f4;
}

.sort-div>div>a {

  font-weight: 400;
  font-size: 13px;
  line-height: 128.91%;
  /* identical to box height, or 17px */

  color: #262626;
}

.pro-pic {
  height: 98px;
  width: 98px;
  margin-bottom: 16px;
  margin-left: 32px;
  cursor: pointer;
}

.tagsandlabels {
  margin-top: 24px;
}

.tagsandlabels>p {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 4px;
  line-height: 16px;
  /* identical to box height, or 17px */
  letter-spacing: 0.01em;
}

.tagsandlabels img {
  height: 21px;
  width: 21px;
  margin-left: 6px;
}

.tagsandlabels>button {
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  padding: 0px 8px;
  /* identical to box height, or 17px */
  background: #DBF1FF;
  border-radius: 5px;
  margin-right: 16px;
  letter-spacing: 0.01em;

  color: #000000;
}

.otp-box {
  display: flex;
  justify-content: space-between;
}

.otp-box>div {
  height: 67px;
  line-height: 67px;
  width: 65px;
  text-align: center;
  font-size: 50px;
  border: 1px solid #ccc;
}

.w-100-a {
  width: 100%;
  text-align: center;
}

.view-detail-short p {
  font-weight: normal;
  font-size: 16px;
  line-height: 128.91%;
  /* or 14px */

  margin-bottom: 4px;
  letter-spacing: 0.01em;

  color: #000000;
}

.view-detail-short img {
  height: 60px;
  width: auto;
  margin-right: 16px;
}

.view-detail-short>.display>div {
  margin-right: 16px;
}

/* Miscellaneous */

/* Display tab content */
.tabcontent {
  display: none;
}

.tabcontent .form-section {
  height: calc(100vh - (116px + 60px + 30px + 50px));
}

/* FAQ's And Category */
.box-educator.h-a {
  height: auto;
}

.height-adjust {
  height: calc(100vh - (116px + 60px + 90px));
  overflow-y: auto;
}

.added-by>p.position_number {
  font-size: 16px;
  margin-left: 8px;
}

.blue_up {
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height */
  cursor: pointer;
  letter-spacing: 0.01em;

  color: var(--proctur-blue);
}

.answer-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.01em;
  margin-bottom: 16px;
  color: #334D6E;
}

.box-educator.h-a.w-b-100>div {
  width: 100%;
}

.box-educator.h-a.w-b-100>div:not(:nth-child(2n)) {
  margin-right: 0px;
}

.view-all {
  display: flex;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */
  width: 848px;
  cursor: pointer;
  align-items: center;
  margin: 0px auto;
  letter-spacing: 0.01em;
  color: var(--proctur-blue);
}

.view-all>p {
  min-width: 150px;
  margin-bottom: 0px;
  text-align: center;
}

.view-all .view-line {
  width: 100%;
  height: 1px;
  background: #EFEFEF;
}

/* Tabs */
.nav-item .nav-link {
  font-weight: 400;
  color: #0097F9;
  cursor: pointer;
  font-size: 14px;
}

.nav-link {
  display: block;
  padding: 8px 1rem;
  margin-right: 1rem;
  margin: -1rem 2.5rem;
  margin-left: 0rem;
}



.nav>li {
  display: inline;
}

.nav-link:hover,
.nav-link,
.nav-link:focus {
  background-color: transparent;
}

.nav-link {
  display: inline;
}

.nav-item .active {
  background: #109CF1;
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.01em;
}

.main-shadow {
  border-bottom: 1px solid #EFF0F5;
  height: 34px;
  margin-top: 16px;
}

.box-view3 {
  width: 848px;
  margin: 10px auto;
  overflow-y: scroll;
  height: calc(100vh - (116px + 20px + 90px));
}

.grey-head {
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 35px;
  /* identical to box height */
  background: #EFF0F5;
  border-radius: 2px;
  letter-spacing: 0.01em;
  align-items: center;
  /* table_black */

  color: #323C47;
}

.grey-head>div,
.white-body>div {
  width: 20%;
  padding-left: 8px;
}

.grey-head>div:first-child,
.white-body>div:first-child {
  width: 40%;
}

.grey-head>div:last-child,
.white-body>div:last-child {
  margin-left: auto;
}

.white-body {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */
  letter-spacing: 0.01em;
  /* table_black */
  color: #323C47;
  align-items: center;
  padding: 9px 0px;
  background: #fff;
  border: 1px solid #EFF0F5;
}

.white-body:last-child {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
}

/* Profile Page Responsive CSS */
@media (max-width: 850px) {
  .scroll-solve {
    width: 100%;
    overflow-y: scroll;

  }
  .progress_bar>div>div>div {
   width: 130px;
  }
  .scroll-solve::-webkit-scrollbar {
    display: none;
  }

  .progress_bar {
    width: max-content;
  }

  .display.progress_bar-outter {
    display: block;
    padding: 8px;
    margin-top: 10px;
  }

  .display.progress_bar-outter>div:last-child {
    margin-top: 10px;
  }

  .display.f-wrap {
    display: block;
  }

  .display.f-wrap>div {
    margin: 0px 0px 42px;
    width: 100%;
  }

  .form-section>div,
  .flex-p1,
  .form-section>div input[type=date].expandale-input,
  .form-section>div .expandale-input.w-450,
  .form-section>div .expandale-input.w-200,
  .select-wrapper.w-450 {
    width: 100%;
  }

  .form-section>div .expandale-input.w-200 {
    margin-right: 0px;
  }

  .form-section>div .w-70.flex-p1 .expandale-input.w-450 {
    width: 70%;
  }

  .form-section>div.flex {
    display: block;
  }

  .flex-a.mob-res {
    display: block;
  }

  .flex-inline {
    margin-left: 0px;
  }

  .select-wrapper {
    width: 100%;
    margin-left: 0px;
  }

  .box-section,
  .box-section1,
  .box-educator1,
  .box-educator,
  .box-educator1,
  .box-view3 {
    width: 100%;
  }

  .box-section>div:not(:nth-child(3n)),
  .box-educator>div:not(:nth-child(2n)) {
    margin-right: 0px;
  }

  .box-section>div,
  .box-educator>div {
    width: 100%;
  }

  .mob-top {
    margin-top: 16px;
  }

  .form-section>div .expandale-input.w-450.mob-20 {
    width: calc(100vw - 196px);
  }

  .flex.mob-v {
    display: block;
  }

  .hidden1 {
    display: none;
  }

  .flex-a.mt-16.mob-res>.mr-50 {
    margin-right: 0px;
  }

  .mr-70 {
    margin-right: 0px;
  }

  .box-educator1.mob-v,
  .contentv:last-child .box-educator1.mob-v {
    display: block;
  }

  .box-educator1.mob-v>div:last-child {
    margin-top: 16px;
  }

  .search {
    width: calc(100vw - 248px);
  }

  .tooltip1 .tooltiptext1.large,
  .tooltip1 .tooltiptext1 {
    position: fixed;
    left: 0px;
    min-width: 100%;
  }

  .box-section>div span,
  .box-educator>div .flex-a span.ml1-2 {
    margin-left: 0px;
  }

  .tab {
    width: max-content;
  }

  .contentv:last-child .box-educator1.mob-v>div:last-child {
    display: flex;
    justify-content: end;
  }

  .view-all {
    width: 100%;
  }

  .height-adjust {
    height: auto;
  }

  .form-section>div .w-14 {
    width: 100%;
    margin-left: 0px;
    margin-top: 24px;
  }

  .flex-inline:not(:first-of-type) {
    margin-left: 00px;
  }

  .mt-36 {
    margin-top: 16px;
  }
}

/* Educators CSS */
.w-40>div {
  width: 35%;
}

.description {
  height: 70px;
}

input[type=file]::file-selector-button {
  border-radius: .2em;
  background-color: var(--proctur-blue);
  height: 35px;
  border: none;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.form-section>div .expandale-input.fees {
  width: min-content;
}
.small-label{
  font-weight: 600;
font-size: 10px;
line-height: 14px;
/* identical to box height */

letter-spacing: 0.01em;

color: #CACBCC;

}

span.branch span.data-info{
  font-weight: 400;
  font-size: 10px;
  line-height: 128.91%;
  /* identical to box height, or 13px */
  letter-spacing: 0.01em;
  margin-bottom: 8px;
  color: #AFAFAF;
}
.flex-inline.dynamic-ml{
  margin-left: 145px;
}
.dynamic-ml .flex-a{
  margin-right: 12px;
}
/* Educators Page Responsive CSS */
@media (max-width: 850px) {
  .flex.w-40 {
    display: block;
  }

  .w-40>div {
    width: 100%;
  }

  .space .flex-inline {
    margin-left: 20px;
  }

  .grey-head,
  .white-body {
    line-height: 18px;
    padding: 9px 0px;
  }

  .white-body .ml16 {
    margin-left: 0px;
    margin-top: 4px;
  }

  .grey-head>div,
  .white-body>div {
    width: 18.5%;
  }

  .grey-head>div:nth-child(2),
  .white-body>div:nth-child(2) {
    width: 23%;
  }

  .box-view3>.display {
    flex-wrap: wrap;
  }

  .box-view3>.display .blue_button {

    margin: 8px 0px;
    margin-left: auto;
  }

  .form-section>div .expandale-input.w-300 {
    width: 100%;
  }
  .dynamic-ml{
    margin-left: 145px;
  }
}

/* Settings */
.notif-info {
  font-weight: 400;
  font-size: 13px;
  line-height: 128.91%;
  /* identical to box height, or 17px */
  letter-spacing: 0.01em;
  margin-bottom: 0px;
  color: #696969;
}

.deactivatebutton {
  background: #F19710;
  border-radius: 2px;
  padding: 0px 16px;
  margin-right: 16px;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.deletebutton2 {
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  /* identical to box height */
  background: #CD0E0E;
  border-radius: 2px;
  letter-spacing: 0.01em;
  padding: 0px 16px;
  color: #FFFFFF;

}

.unlink-svg {
  height: 90px;
  margin: 8px 0px 8px 32px;
}

/* Settings Responsive CSS*/
@media (max-width: 850px) {
  .notif-info {
    width: 70%;
  }
}

/* News */
.box-educator.h-a2 {
  height: calc(100vh - (60px + 120px));
}

.news-box {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 16px;
}

.news-box>p {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.01em;
  margin-bottom: 0px;
  color: #334D6E;
}

.form-section.news {
  height: calc(100vh - (100px));
}

.noun-tip {
  width: auto;
  height: 35px;
  margin-right: 16px;
}

.box-section1.news {
  flex-wrap: wrap;
}

.box-section1.news>div {
  margin-top: 10px;
  margin-left: auto;
}

/* Branches */
.box-educator>div .flex-a .checkbox span {
  display: block;
  font-size: 0px;
  margin-right: 0px;
}

.box-educator>div .flex-a span.branch {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */
  color: #323941;
  padding: 0px;
  display: block;
  opacity: 0.8;
  margin-left: -16px;
}

span.branch span.data-info {
  font-weight: 400;
  font-size: 10px;
  line-height: 128.91%;
  /* identical to box height, or 13px */
  letter-spacing: 0.01em;
  margin-bottom: 8px;
  color: #AFAFAF;
}

.branch-box {
  background: #FFFFFF;
  border-radius: 5px;
  width: 146px;
  height: 62px;
  display: flex;
  margin-right: 10px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
}

.branch-box>p {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.01em;
  margin-bottom: 0px;
  color: #334D6E;
}

.branch-box>span {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.01em;

  color: #109CF1;
}

.box-educator.branches {
  height: calc(100vh - (116px + 60px + 110px));
}

.branch-details {
  width: 848px;
  margin: 0px auto;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #334D6E;
  background-color: #fff;
  padding: 16px;
}

.branch-details>p {
  margin-bottom: 0px;
}

.class-or {
  text-align: center;
  color: #ff0000;
  font-weight: 700;
  margin-top: 16px;
}

.option-div {
  margin-left: auto;
  width: 60px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #CACBCC;
}

.option-div>p {
  margin-bottom: 8px;
}

/* branch responsive design */
@media (max-width: 850px) {
  .branch-details {
    width: 100%;
  }

  .branch-box {
    min-width: calc((100vw - 142px) / 2);
    width: calc((100vw - 142px) / 2);
    padding: 0px 8px;
  }

  .box-educator.branches {
    height: auto;
  }
}


/* Roles */

/* Switching Tabs */
.nav-tabs .nav-link {
  border: none;
  padding: 8px 24px;
  margin: 0px;
  font-weight: 600;
  color: var(--proctur-blue);
}

/* .nav-item {
  padding: 8px 0px;
} */

.box-educator>div .flex-a span.assign_number {
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  color: var(--proctur-black);
}


.dropdown-content-1 {
  display: none;
  max-width: 300px;
  background-color: #fff;
  position: absolute;
  margin-top: -4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 8px;
}

.dropdown-content-1 p:last-of-type {
  margin-bottom: 0px;
}

.dropdown-1:hover .dropdown-content-1,
.dropdown-1:focus .dropdown-content-1,
.dropdown-1:active .dropdown-content-1 {
  display: block;
}

.display.f-wrap1 {
  display: block;
}

.display.f-wrap1>div {
  width: 30%;
}

.display.f-wrap1>div.flex-inline {
  margin-left: 0px;
}

.display.f-wrap1>div.flex-inline>.checkbox {
  margin-bottom: 0px;
}

.form-section.roles {
  height: calc(100vh - (150px));
}

.nav-tabs .nav-item {
  margin-bottom: 8px;
}



/* Media Quires for Role */
@media (max-width: 850px) {
  .display.f-wrap1>div {
    width: 100%;
  }

  #role .mt-36:not(:first-of-type) {
    margin-top: 36px;
  }

  .form-section.roles {
    height: calc(100vh - (140px));
  }
}


/* Review */
.filter-box {
  min-height: 23px;
  /* max-height: 90px; */
  border: none;
  background-color: #fff;
  font-weight: 400;
  font-size: 13px;
  /* line-height: 128.91%; */
  line-height: 269.91%;
  letter-spacing: 0.01em;
  margin-bottom: 0px;
  width: 150px;
  margin-left: 16px;
  color: var(--proctur-black);
}

input.filter-box {
  padding-left: 8px;
}

.filter-box>select:focus,
.filter-box>select,
input.filter-box:focus {
  border: none;
  outline: none;
  padding-left: 8px;
}

.flex>.select-wrapper.filter-box {
  width: 150px;
  margin-right: 0px;
}

.filter-box select option,
.fa.fa-star,
.fa.fa-star-half-o {
  color: #FFD700;
  font-size: 16px;
  padding-top: 0px;
  margin-top: 0px;
}


.grey-head1 {
  display: flex;
  width: 848px;
  margin: 0px auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 35px;
  /* identical to box height */
  background: #EFF0F5;
  border-radius: 2px;
  letter-spacing: 0.01em;
  align-items: center;
  /* table_black */
  color: #323C47;
}

.grey-head1>div,
.white-body1>div {
  width: calc((50% - 60px)/ 3);
  padding-left: 12px;
}

.grey-head1>div:first-child,
.white-body1>div:first-child {
  width: 60px;
}

.grey-head1>div:last-child,
.white-body1>div:last-child,
.grey-head1>div:nth-child(3),
.white-body1>div:nth-child(3) {
  width: 25%;
}

.grey-head1>div:nth-child(5)>p {
  line-height: 18px;
  margin-bottom: 0px;
}

.white-body1>div:nth-child(5),
.grey-head1>div:nth-child(5) {
  text-align: center;
  padding-left: 0px;
}

.white-body1 {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */
  letter-spacing: 0.01em;
  /* table_black */
  color: #323C47;
  align-items: center;
  padding: 9px 0px;
  background: #fff;
  border: 1px solid #EFF0F5;
}

.white-body1:last-child {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
}

.table-v {
  height: calc(100vh - (60px + 120px));
  width: 848px;
  overflow-y: auto;
  margin: 0px auto;
}

.white-body1>div>label {
  display: none;
}

.flex.w-501>div {
  width: 50%;
}

.flex.w-501.review-star>div label {
  width: 105px;
  margin-bottom: 0px;
}

/* Media Quires for Review */
@media (max-width: 850px) {
  .table-v {
    width: 100%;
  }

  .grey-head1 {
    display: none;
  }

  .flex>.select-wrapper.filter-box {
    margin-left: auto;
    margin-top: 10px;
  }

  .review.box-section1 {
    flex-wrap: wrap;
  }

  .review.box-section1>div {
    margin-left: auto;
  }

  .review.box-section1 .search {
    width: 150px;
    margin-bottom: 10px;
  }

  /* Table responsive view */
  .white-body1 {
    display: block;
    border: 1px solid #ccc;
    padding-left: 8px;
    margin-bottom: 12px;
  }

  .white-body1>div:nth-child(n) {
    width: 100%;
    padding-left: 0px;
    display: flex;
    text-align: initial;
    margin-bottom: 4px;

  }

  .white-body1>div>label {
    width: 35%;
    display: initial;
    font-weight: 700;
  }

  .flex.w-501 {
    display: block;
  }

  .flex.w-501>div {
    width: 100%;
  }

  .flex.w-501>div:last-child {
    margin-top: 16px;
  }

  .form-section.review {
    height: auto;
  }
}

/* KYC Section */

.kyc-section>h2 {
  color: var(--proctur-black);
  font-size: 16px;
  font-weight: 700;
}

.kyc-section>p {
  color: var(--proctur-black);
  font-size: 14px;
  margin-bottom: 0px;
}

.kyc-section>p:not(:last-of-type) {
  margin-bottom: 8px;
}

.tags-img.wrap>div {
  flex-wrap: wrap;
  width: 10%;
  margin-right: 16px;
}

.tags-img.wrap>div>p {
  width: 100%;
  font-size: 13px;
  margin-bottom: 0px;
  height: 70px;
}

.button-section1 {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.form-section .kyc-display {
  display: flex;
  flex-wrap: wrap;
  margin: 16px auto;
  width: 848px;
  padding: 0px;
  background: #f9f9f9;
  justify-content: space-between;
  align-content: flex-start
}

.kyc-display>div {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 49%;
  padding: 16px;
  margin-bottom: 10px;
}

/*KYC Responsive CSS */
@media (max-width: 850px) {
  .kyc-display>div {
    width: 100%;
  }

  .form-section .kyc-display {
    width: 100%;
  }
}

/* Notification */
.notification-box {
  height: calc(100vh - (90px));
  overflow-y: auto;
}

.notification-box::-webkit-scrollbar {
  display: none;
}

.notification-box>div {
  width: 848px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 16px 24px;
  margin: 0px auto 10px;
}

.notification-day {
  font-weight: 600;
  font-size: 13px;
  line-height: 128.91%;
  letter-spacing: 0.01em;
  margin-bottom: 16px;
  color: #AFAFAF;
}

.notification-box .flex {
  padding: 16px 0px;
}

.notification-box div.flex>span.circle {
  display: inline-block;
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  margin-right: 16px;
}

.notification-box div.flex>p {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: var(--proctur-black);
  width: calc(100% - (30px + 16px + 150px));
  margin-bottom: 0px;
}

.notification-box div.flex>span:last-of-type {
  margin-left: auto;
  font-weight: 600;
  font-size: 13px;
  line-height: 128.91%;
  letter-spacing: 0.01em;
  margin-bottom: 16px;
  color: #AFAFAF;
}

.notification-box div.flex>span.circle.blue {
  background-color: #64acf2;
}

.notification-box div.flex>span.circle.olive {
  background-color: #b0b17b;
}

.notification-box div.flex>span.circle.orange {
  background-color: #f7be63;
}

.notification-box div.flex>span.circle.purple {
  background-color: #8b87c1;
}

.notification-box div.flex>span.circle.teal {
  background-color: #63bfc6;
}

/*Notification Responsive CSS */
@media (max-width: 850px) {
  .notification-box>div {
    width: 100%;
    padding: 8px;
  }

  .notification-box div.flex>p {
    width: calc(100% - 95px);

  }

  .notification-box div.flex>span:last-of-type {
    text-align: right;
    margin-top: 4px;
  }

  .notification-box {
    margin-top: 10px;
    height: calc(100vh - 110px);
  }
}
.inputNew{
  display: flex;
}

/* .sidebar-wrapper{
  position:fixed;
  width:300px;
  height:100%;
  background-color:orange;
  top:0;
  right:0;
  margin-right:-250px;
}
.sidebar-wrapper.opened{
  margin-right:0px;
}

.toggle {
  position:relative;
  display:block;
  width:50px;
  height:100%;
  background-color:grey;
  float:left;
  
  /* push text out of the Result overlay */
  /* padding-top: 3em; 
}

.cart {
  position: relative;
  width:250px;
  height:100%;
  background-color:red;
  float:right;
} */ 