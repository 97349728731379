.entire_reports
{
    
    word-break: break-word;
    font-weight: bold;
    font-size: 1vw;
    width:100%;
    overflow-x: auto;
    overflow-y: auto;

    /* border:2px solid red */
}

.sa_reports_inner
{
  
    
    padding:40px;
    padding-top: 10px;
    /* border:2px solid black; */
}
.sa-LeadsNav44{
    background: white;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    /* font-size: 16px; */
    padding: 0px 20px;
    max-width: 100%;
    min-width: 800px;
    /* margin-left: 20px; */
    /* border: 1px solid black; */
    margin-top: 10px;
  
  }

  .sa-leadsHead22{
    display: flex;
    list-style: none;
    justify-content:space-between;
    height: 43px;
    width: 20%;
    /* border: 1px solid pink; */
  }

.reports_heading_and_cards
{

    width: 100%;
    /* height:15vh; */
    /* border:1px solid red; */
}

.sa_reports_heading
{
    /* border:2px solid black; */
}

.sa_reports_cards
{
    height: 14vh;
   
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* border:2px solid red; */
}

.sa_reports_cards_inner
{
    width: 100%;
    min-height: 70px;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    /* border:2px solid black; */
}

.reports_cards
{
    
    
    width: 29%;
    border-radius: 8px;
    background-color: #FFFFFF;
    cursor: pointer;
    border:0.5px solid black;
}

.reports_cards p
{
    color: #0097F9;
    margin-top: 13px;
}