.sa-containers{
    display:flex;
    flex-wrap: 2;
    justify-content:left;
    padding: 0;
    margin: 0;
    color:#FFFFFF;
    box-sizing: border-box;
    word-wrap: break-word;
    width: 100%;
    height: 100vh;
    color:white;
}

input:-webkit-autofill{
  -webkit-text-fill-color: #000 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
 -webkit-box-shadow: 0 0 0 30px #FFF0 inset !important;
}



.sa-login-container-6{
    height: 100vh;
    background-image:url('../../assets/login/login_bg.png');
    background-position:center;
    background-repeat: no-repeat;
    flex: 1.5;
    background-size: contain;
    /* border: 1px solid black; */
    background-color: #c5ddf4;
}

  

.sa-login-image{
  flex: 1;
  height:auto;
    
}
.sa-login-container-4{
    flex: 1;
    height: 100vh;
    /* border: 1px solid black; */
  
}

.sa-login-container-head{
  display:grid;
  row-gap: 15px;
  text-align: left;
  letter-spacing: 1px;
  padding-top: 15vh;
  /* border: 1px solid black ; */
}
.sa-login-heading-b{
  letter-spacing: 2px;
  font-size: 60px;
  line-height: 60px;
  font-weight: 500;
  text-shadow: 1px 2px 1px rgb(170,159,159);
  color:#1E6FE5;
 
 
}

.sa-login-heading-m{
  font-size:30px;
  /* margin-top:10px; */
  margin-bottom: 20px;
  font-weight: 400;
  text-shadow: 1px 2px 1px rgb(170,159,159);
  color:#1E6FE5;
  /* margin-top: 15px; */
}

/* ------------------- x section start -------------------*/

.sa-login-text-s{
  margin-bottom: 10px;
  font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;

color: #222222;
 
}

.sa-partition{
  height: 2px;
  width: 70px;
  margin-bottom: 12px;
  margin-top: 30px;;
  background-color: #d5dff3;
}

/* ------------------- x section end -------------------*/


.sa-containers::placeholder{
  color:white;
  font-family: Lato;
}


.sa-containers::-ms-input-placeholder{
  color:white;
  font-family: Lato;
}
.sa-login-input-container input ::placeholder {
  color: red;
}

.sa-login_placeholder {
  font-family: Lato;
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 11px;
/* identical to box height, or 85% */
background: none;
color: #000;
}


/* ::-webkit-input-placeholder {
  color: #FFFFFF;
 } */


/* .containers{
  padding: 0;
  margin: 0;
  color:#FFFFFF;
  box-sizing: border-box;
  word-wrap: break-word;
  font-family: Lato;
  font-family: Lato;
} */

.sa-containers a {
  /* color: #FFFFFF; */
  text-decoration: unset;
}
.sa-containers:span {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color:#FFFFFF;
}

.sa-login-input-container {
  padding-bottom: 24px;
  color:#FFFFFF;
}


.sa-formbg {
  min-height: calc( 100vh );
  text-align: left;
    margin: 0px auto;
    padding: 0 20%;
    padding-bottom: 0;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
    border: 1ps solid blue;
}
.sa-reset-pass  {
    margin: 10px 0px;
    font-size: 16px;
    font-weight: 300;
    display: block;
    color:#FFFFFF;
    text-align: right;
    margin-bottom: 10px;
    cursor: pointer;
    /* color: #FFFFFF; */
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}



.sa-login-input-container input  {
    font-size: 14px;
    line-height: 28px;
    padding: 8px 17px;
    
    width: calc( 100% - 10px );
    /* min-height: 44px; */
    border: unset;
    margin-top: 5px;
    border-radius: 4px;
    outline: unset;
    background-color: #FFF;
    /* border: 1px solid black; */
    border: 1px solid rgba(106, 106, 106, 0.6);
box-sizing: border-box;
filter: drop-shadow(0px 4px 4px rgba(79, 161, 233, 0.26));
border-radius: 5px;
   
}

.sa-login-submit-button{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width:  calc( 100% - 10px );
  height: 46px;
  font-weight: 600;
  margin-top: 5px;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: #1F77E6;
  cursor: pointer;
  color: #ffffff;
  margin-top: 30px;
    /* border: 1px solid blueviolet; */
 
}
/* .login-submit-button:hover{
  border:1px solid #b4b3b3;
} */

.sa-login-password-field{
  display:flex;
  align-items: center;
  justify-content: right;
  font-size: 14px;
  line-height: 28px;
  padding: 12px 17px;
  width: calc( 100% - 10px );
  /* height: 44px; */
  border: unset;
  margin-top: 5px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgba(106, 106, 106, 0.6);
box-sizing: border-box;
filter: drop-shadow(0px 4px 4px rgba(79, 161, 233, 0.26));
border-radius: 5px;
}
.sa-login-password-field input{
  margin: 0 10px;
  font-size: 14px;
  width: 90%;
  border: unset;
  outline:unset;
 
}

input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }
      

.password-eye{
  cursor: pointer;
}

.sa-login-submit-button i{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 16px;
  margin-left: 8px;
  background-color: #FFFFFF;
  width: 18px;
  height:18px;
  padding: 0;
  color:    #1F77E6;
  /* border: 1px solid blueviolet; */
}

.sa-ssolink {
    display: block;
    text-align: center;
    font-weight: 500;
  justify-content: center;
  
font-family: Lato;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

color: #222222;
  margin-top: 20px;
  /* color: #222222; */
 cursor: pointer;
  /* border: 1px solid blueviolet; */
  width: calc( 100% - 10px );
  
}
@media (max-width:800px) {
  .sa-login-container-6{
    flex: 0;
    
  }
  .sa-formbg{
    padding:5%;
  }
}


@media (max-width:600px) {
  .sa-login-heading-b{
    font-size: 50px;
    
  }
  .sa-login-heading-m{
    font-size:24px;
  }
}


 