.sa_home_container
{
    height:90vh;
    width:100%;
    word-break: break-word;
    
    /* border:2px solid black; */
}
.sa_home_inner_container
{

    padding: 15px;
    padding-left:20px ;
    height:calc(90vh);
    width: calc(100%);
    /* border:2px solid green; */
}
.sa_home_upper_section
{
height:calc(50%);
width:100%;
/* border:1px solid red; */
}



.home_logo_part
{
    /* height:40%; */
    width:100%;
    display: flex;
    /* border:1px solid green; */
}

.home_logo_frame
{
    height:10vh;
    width: 10vh;
    border-radius: 5px;
    /* border:1px solid black; */
}

.home_logo_text
{
    height:10vh;
    margin-left: 5px;
    width:100px;
    font-size: 1vw;
    display: flex;
    align-items: center;
    /* border:1px solid brown; */
}

.home_welcome_part
{
    height:calc(60%);
    display: flex;
    justify-content: center;
    width:100%;
    /* border:1px solid black; */
}
.welcome_inner_container
{
    height:100%;
    width:65%;
     display: flex;
     justify-content: center;
     /* border:1px solid brown; */
}
.welcome_and_three_button_container
{
    height:100%;
    width: 80%;
   
}

.welcome_text_and_an_image
{
    height:50%;
/* border:1px solid brown; */
}
.welcome_text_and_an_image_inner
{
    height:25%;
    display: flex;
    justify-content: center;
    width: 100%;
    /* border:1px solid blue; */
}
.tata_imgae
{
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    
    /* border: 1px solid gray; */
}

.home_welcome_text
{
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1.8vw;
    font-weight: bold;
    color: #0D68FF;
    margin-left: 5px;
    

}

.home_three_buttons_and_text
{
    height:50%;
    /* border:1px solid brown; */
}
.three_buttons_upper_text
{
    height:35%;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    font-size: 1.0vw;
    font-weight: bold;
    margin-bottom: 10px;
    /* border:1px solid brown; */
}
.home_three_buttons_outer
{
    height:65%;
    display: flex;
    justify-content: center;
   
  /* border:1px solid yellow; */
}
.home_three_buttons_inner
{
    height:70%;
    width: 100% ;
    display: flex;
    justify-content: space-evenly;
    /* border:1px solid yellow; */
}
.three_btn
{
    height:100%;
    background: rgb(225, 217, 217);
    width:30%;
    border-radius: 7px ;
    font-size: 1.2vw;
    /* min-width: 80px; */
}

/*     ==================================================HOME LOWER ==========================================*/

.sa_home_lower_section
{
    padding: 15px;
    height:calc(38%);
    width:100%;
    border-radius: 10px;
    background-color: #FFFFFF;
    /* border:2px solid black; */
}

.sa_home_lower_section_inner
{
    width: 100%;
    height: 100%;

    /* border:1px solid black; */
}
.entire_form_fillup_section
{
    height:87%;
    /* border:1px solid brown; */

}
.fill_section_upper_heading_and_btn
{
    height:23%;
    display: flex;
    /* border:1px solid black; */
}
.fillup_headings
{
    width:45%;
    font-size: 0.9vw;
    font-weight: bold;
    /* border:1px solid black; */
}

.fillup_upper_btn
{
    width:55%;
    display: flex;
    justify-content: end;
    /* border:1px solid black; */
}
.fillup_upper_btn button
{
    color:#0D68FF;
    background: inherit;
    font-size: 0.8vw;
    font-weight: bold;
    border:0;
    
}

.form_fillup_section
{
    height:77%;
    display: flex;
    /* border:1px solid blue; */
}
.home_lower_cards_left_section
{
    height: 100%;
    width:45%;
    /* border:1px solid black; */

}
.left_form_fillup_line1
{
    height:21%;
    display: flex;
    justify-content: start;
    /* border:1px solid black; */
}
.left_form_fillup_line1_inner
{
    height: 100%;
    width:82%;
    font-size: 1.1vw;
    margin-top: 15px;
  
     /* border-bottom: 2px solid black; */
}
.left_form_fillup_line2
{
    height:21%;
    display: flex;
    /* border:1px solid black; */
}
.left_form_fillup_line2_inner1
{
    height: 100%;
    width:82%;
    font-size: 1.1vw;
    /* font-weight: 600; */
    /* border-bottom: 2px solid black; */
    margin-top: 35px;
}
.left_form_fillup_line2_inner2
{
    width:12%;
   
    /* border:1px solid blue; */
}
.left_form_fillup_line3
{
    height: 14%;
    width: 82%;
    font-weight: bold;
    text-indent: 5px;
    font-size: 0.7vw;
    /* border:1px solid black; */
    margin-top: 5px;
   
}
.home_lower_cards_right_section
{
    height: 100%;
    width:55%;
    /* border:1px solid brown; */

}
.lower_right_headings
{
    height:21%;
    font-size: 1vw;
    font-weight: bold;
    display: flex;
/* border:1px solid blue; */
}
.lower_right_headings font
{
    color: gray;
    font-size: 0.7vw;
    display: flex;
    align-items: center;
}

.lower_right_text_area
{
    /* height:67%; */
    width: 100%;
    /* border:2px solid black; */
}

.form_submit_section
{
    height:13%;
    display: flex;
    margin-top: 20px;
    /* justify-content: center; */
    /* border:1px solid brown; */
}

.home_lower_submit_btn
{
    width: 5%;
    min-width: 60px;
    font-size: 0.8vw;
    background: rgb(225, 217, 217);
    border-radius: 5px;
    border:1px solid black;

}

