/* =============================================report,Inst ,educator and student reports have combined css =======================*/

.inst_reports
{
 width: 100%;   
 /* border:2px solid black; */
 font-weight: bold;
 font-size: 1vw;
 overflow-x: auto;
 overflow-y: auto;
}

.inst_reports_inner
{
    min-width: 900px;
    min-height: 600px;
}

.entire_reports_2
{
    
    word-break: break-word;
    font-weight: bold;
    font-size: 1vw;
    width:100%;
  
    overflow-x: auto;
    overflow-y: auto;

    /* border:2px solid red; */
}

.sa_reports_inner
{
  
    
    padding:40px;
    padding-top: 10px;
    /* border:2px solid black; */
}

.sa_reports_heading2
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    /* border:1px solid black; */
}
.reports_heading_filters
{
    display: flex;
    /* cursor: pointer; */
    justify-content: space-between;
    position: relative;
    /* border:1px solid red; */
}

.reports_from_date_filter_area
{
    display: flex;
    width: 45%;
    /* border: 1px solid blue; */
    position: relative;
}

.reports_absolute_inputs
{
   /* display: none; */
    width: 100%;
    /* width: 100px; */
    /* position: absolute; */
    /* border:1px solid green; */
}
.reports_absolute_inputs:hover
{
  display: block;
}
.reports_absolute_inputs input
{
  
  width: 100%;
}
/* .reports_absolute_inputs input:hover
{
    background: red;
} */
.reports_non_absolute_inputs
{
    width: 100%;
    /* border:1px solid brown; */
}
/* .reports_non_absolute_inputs:hover
{
  display: none;
} */
.reports_non_absolute_inputs input
{
    background-color: inherit;
    border: 0;
    border-bottom:3px solid black ;
    width: 100%;
}

/* .reports_non_absolute_inputs input:hover
{

background: green;
} */

.sa_reports_cards_inner_2
{
    width: 100%;
    min-height: 70px;
    height: 12vh;
    display: flex;
    justify-content: space-evenly;
    /* border:2px solid black; */
}



.state_wise_reports
{
    /* min-width: 900px; */
    padding-left: 40px;
    padding-right: 40px;

}

.state_wise_reports_inner
{
    /* height: 100%; */
    height:16vh;
    min-height: 130px;
    border-radius: 8px;
    padding:10px;
 
    border: 2px solid black;
}

.heading_state_wise
{
    height:35%;
    /* border: 1px solid blue; */
}

.state_wise_reports_data_section
{
    display: flex;
    overflow-x: auto;
    /* border: 1px solid black; */
}

.state_individual_data
{
    padding-right: 20px;
    padding-left: 20px;
    /* border: 1px solid blue; */

}

.student_cards_area
{
    padding-left: 40px;
    padding-right: 40px;
    height: 16vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100px;
    /* border: 1px solid black; */
}

.student_cards_area_inner
{
    width: 100%;
    display: flex;
    justify-content: space-between;
      /* border: 1px solid blue; */

}

.student_cards
{
    width: 30%;
    
    min-height: 100px;
    height:14vh;
    border-radius: 8px;
    display: flex;
    /* justify-content: space-around; */
    flex-direction: column;
    border: 2px solid black;

}
.stu_cards_status_btn_p
{
 
    margin-top: 5px;
    font-size: 0.8vw;
    font-weight: normal;
    display: flex;
    justify-content: center;

}


.stu_cards_status_btn
{
    background-color: rgb(199, 190, 190);
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
       border: 1px solid black;
}