.Sa-Profile {
  /* border:1px solid blue; */
  /* width: 100%; */
  margin-left: 230px;

}

.Sa-Profile2 {
  /* border:1px solid blue; */
  width: 100%;
  /* margin-left: 10px; */

}

.Super-admin-line_purple {
  width: 37px;
  height: 4px;
  /* margin-top: 4px; */
  background: rgba(110, 98, 255, 0.74);
}

.Sa-AddProfile {
  /* border:1px solid red; */
  width: 100%;
  height: fit-content;
  left: 0px;
  top: 0px;
  margin-top: 20px;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.Super-admin-buttons {
  /* border:1px solid yellow; */
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.Sa-Inputbox {
  display: flex;
  flex-direction: row;
  /* border:1px solid black; */
  justify-content: space-between;
  margin-top: 10px;
  /* padding: 10px; */
  width: 700px;

}

.Sa-input {
  width: 300px;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #E0E0E0;
  /* margin-left: 10px; */

}

.Super-admin-cancel-button {
  border-radius: 2px;
  height: 30px;
  border: 1px solid #939393;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */
  padding: 0px 16px;
  letter-spacing: 0.01em;

  color: #636363;
  background: #ffffff;
}

.Super-admin-save-button {
  background: #0097F9;
  border-radius: 2px;
  font-weight: 600;
  height: 30px;
  padding: 0px 8px;
  font-size: 13px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #FFFFFF;
  border: none;
  padding: 0px 16px;
  margin-left: 10px;
}

.Super-admin-searchhhhhhhhhhh {
  /* border:1px solid black; */
  /* margin-top: 5px; */
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.sa-searchhh {
  border: 1px solid #939393;
  border-radius: 15px;
  height: 30px;
  background: #FFFFFF;
  width: 250px;
}

.Super-admin-Search_Input {
  /* border:1px solid black; */
  border: 0;
  font-size: 13px;
  margin-top: 5px;
  border: none;
  border-color: transparent;

}

.Super-admin-Search_Input2 {
  /* border:1px solid black; */
  /* border-radius: 15px; */
  height: 30px;
  border: 0;
  border-bottom: 1px solid #939393;
  font-size: 13px;
  padding: 5px;


}

.Super-admin-Search_Input3 {
  /* border:1px solid black; */
  /* border-radius: 15px; */
  height: 30px;
  border: 0;
  border-bottom: 1px solid #939393;
  font-size: 13px;
  padding: 5px;


}

#super-admin {
  font-family: Lato;
  font-size: 13px;
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 40px;
  border-radius: 5px;
}

#super-admin td,
#super-admin th {
  border: 1px solid #ddd;
  padding: 8px;
}

#super-admin tr:nth-child(even) {
  background-color: #ffffff;
}

#super-admin tr:nth-child(odd) {
  background-color: #ffffff;
}

/* #super-admin tr td:first-child {
    width: 5em;
    min-width: 5em;
    max-width: 5em;
    word-break: break-all;
  } */

#super-admin tr:hover {
  background-color: #ddd;
}

#super-admin th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0097F9;
  color: white;
}

.Sa-dropdown {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.Sa-drop {
  outline: 0;
  border: none;
  border-bottom: 1px solid #E0E0E0;

}

.Sa-dropdown3 {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

}


.Sa-radiobutoon {
  margin-top: 10px;
  width: 300px;
}

.Sa-radio {
  margin-left: 40px;
}

input[type=file] {
  color: transparent !important;
}

input[type=file]::before {
  content: "Upload File ";
  color: black;
  margin-right: 20px;
}

.sa-Standard-Subjects {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: row;
}

.sa-sub {
  /* border:1px solid blueviolet; */
  margin-right: 15px;
  margin-top: 10px;
  text-decoration: underline;
  padding: 3px;

}

.sa-sub2 {
  /* border:1px solid blueviolet; */
  /* margin-right: 15px; */
  margin-top: 19px;
  text-decoration: underline;
  /* padding: 3px; */

}



.sa-LeadsNav {
  background: white;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 0px 20px;
  max-width: 100%;
  min-width: 800px;
  margin-left: 20px;
  /* border: 1px solid black; */
  margin-top: 10px;

}

.sa-leadsHead {
  display: flex;
  list-style: none;
  justify-content: space-between;
  height: 43px;
  width: 25%;
  /* border: 1px solid pink; */
}

.sa-leadsHead2 {
  display: flex;
  list-style: none;
  justify-content: space-between;
  height: 43px;
  width: 15%;
  /* border: 1px solid pink; */
}

.sa-leadsHead3 {
  display: flex;
  list-style: none;
  justify-content: space-between;
  height: 43px;
  width: 10%;
  /* border: 1px solid pink; */
}

/* .sa-leadsSchool {
  
  border-bottom: 3px solid blueviolet;
  padding: 10px; */



.sa-leadsSchool {
  display: flex;
  color: #334D6E;
  text-decoration: none;
  padding: 2px 0px;
  align-items: center;
  border-bottom: 5px solid #FFF;
  cursor: pointer;
  /* border:1px solid maroon; */
}

.sa-leadsSchool-active,
.sa-leadsSchools:hover {
  display: flex;
  color: #334D6E;
  text-decoration: none;
  padding: 2px 0px;
  align-items: center;
  border-bottom: 4px solid #1954E7;
  cursor: pointer;
  /* margin-bottom: 6%; */
}

.sa-leads {
  /* border:1px solid brown; */
  color: #334D6E;
  text-decoration: none;
  padding: 2px 0px;
  align-items: center;
  margin-top: 7px;
  display: flex;
  flex-direction: row;

}

.sa-leadsss {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 800px;
  /* border:1px solid blue */
}

.sa-leadsSchool2 {
  /* border:1px solid black; */
  /* margin-right: 30px; */
  max-width: 100%;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 1000px;
  /* padding: 10px; */

}

/* .Sa-page{
  display: flex;
} */

.Sa-input2 {
  width: 150px;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #E0E0E0;
  margin-top: 19px;


}


.sa-add {
  /* border:1px solid black; */
  margin-right: 20px;


}


.sa-wrapper {
  overflow: hidden;
  position: relative;
  width: 10rem;
  /* real length of dropdown */
}

.sa-wrapper:after {
  content: "▼";
  font-size: 0.7rem;
  pointer-events: none;
  /* ▼ click triggers dropdown */
  position: absolute;
  right: 1rem;
  top: 1.3rem;
  z-index: 1;

}

.sa-wrapper2 {
  overflow: hidden;
  position: relative;
  width: 300px;
  /* real length of dropdown */
}

.sa-wrapper2:after {
  content: "▼";
  font-size: 0.7rem;
  pointer-events: none;
  /* ▼ click triggers dropdown */
  position: absolute;
  right: 1rem;
  /* top: 1.3rem; */
  /* z-index: 1; */

}

.sa-wrapper3 {
  overflow: hidden;
  position: relative;
  width: 130px;
  /* real length of dropdown */
}

.sa-wrapper3:after {
  content: "▼";
  font-size: 0.7rem;
  pointer-events: none;
  /* ▼ click triggers dropdown */
  position: absolute;
  right: 1rem;
  top: 0.3rem;
  /* z-index: 1; */

}

.sa-wrapper4 {
  overflow: hidden;
  position: relative;
  width: 300px;
  /* real length of dropdown */
}

.sa-wrapper4:after {
  content: "▼";
  font-size: 0.7rem;
  pointer-events: none;
  /* ▼ click triggers dropdown */
  position: absolute;
  right: 1rem;
  top: 1rem;
  /* z-index: 1; */

}

.Sa-input3 {
  width: 130px;
  border: 0;
  outline: 0;
  font-size: 8px;
  border-bottom: 1px solid #E0E0E0;
  /* margin-top: 15px; */
  /* border:1px solid blue */

}


.sa-tableLeads {
  /* border:1px solid black; */
  margin-left: 20px;

  /* width: 100%; */
}

.sa-SeachButton {
  /* border:1px solid blue; */
  margin: 10px;
}

.sa-popup {
  /* border:1px solid navy; */
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 70%;
  padding: 10px;
  border-radius: 5px;
}

.sa-popupEnquiry {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sa-Pagination {
  display: flex;
  justify-content: center;
}

#footer {
  position: absolute;
  bottom: 30px;
  width: 80%;

}

.Super-admin-edit-button {
  border: none;
  outline: none;

  background: #0097F9;
  font-weight: 400;
  height: 30px;
  padding: 0px 5px;
  font-size: 13px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.sa-headings {
  font-size: 10px;
  line-height: 128.91%;
  /* identical to box height, or 13px */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  color: #AFAFAF;
  margin-top: 5px;
}


/* @media screen and (width: 700px) {
  .sa-leadsSchool2{
    display: flex;
    flex-direction: column;
      
  }
} */

.SA-homeMAin {
  display: flex;
  flex-direction: column;
  /* border:1px solid black; */
  margin-top: 50px;
  margin-left: 65px;
  width: 500px
}

.Sa-Welcome {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */


  color: #1B2433;

}

.Sa-Manage {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */


  color: #283B4C;
}

.Sa-HomeButton {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.01em;

  color: #FFFFFF;
  background: #4274B0;
  box-shadow: 0px 3px 8px rgba(66, 116, 176, 0.12), 0px 3px 4px rgba(66, 116, 176, 0.12);
  border-radius: 4px;
  width: 230px;
  height: 50px;
  margin-top: 30px;
  /* margin-left: 10px; */
}

.Sa-lable {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #000000;
  margin-top: 20px;
}

.Sa-lable2 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #000000;
}

.SA-homePagePhoto {
  margin-top: 210px;
  width: 500px;
}

.Sa-HomemainDiv {
  display: flex;
  flex-direction: row;
  background: url("../../assets/home/Mask .png") no-repeat center center;
  width: 100%;
  background-color: #f9f9f9;
  margin-left: 15px;
  background-size: cover;
  /* margin-bottom: 500px; */
}

.Sa-DashboardBlock {
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
  border-radius: 8px;
  width: 365px;
  height: fit-content;
}



.Sa-dasboarddiv {
  /* style={{display:"flex" ,flexDirection:"row",justifyContent:"space-between"}} */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 30px;

}

.SA-DashboardLable {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  color: #707070;
  margin-left: 20px;
}

.SA-dashdiv {
  /* border: 1px solid black; */
  margin-top: 15px;
  margin-left: 30px;
  /* display: flex;
  flex-direction: row; */
}

.SA-Percent {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */
  color: #000000;
  /* border: 1px solid magenta; */
  margin-left: 75px;
}

.SA-statDiv {
  background: rgba(0,0,0,0.08);
  box-shadow: 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 30px;
  height: fit-content;
  display: flex;
  flex-direction: row;

}

.SA-partition {
  border: 0.5px solid rgba(0, 0, 0, 0.38);
  /* transform: rotate(90deg) */
  margin: 10px 10px 10px 10px;
}

.SA-ProfileViews {
  width: 350px;
  /* border: 1px solid black; */
}

.SA-ProfileViews1 {
  width: 350px;
  /* height: 200px;
  border: 1px solid black; */
}

.SA-profilelable {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  /* font-size: 20px; */
  line-height: 24px;
  /* or 120% */
  color: #283B4C;
  padding: 10px;

}

.SA-dashboardButton {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  color: #FFFFFF;
  background: #4274B0;
  border-radius: 4px;
  padding: 8px;
  margin-top: 20px;
  margin-bottom: 20px;

}

.SA-profilelable2 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #849AA9;

}

.SA-profilelablediv {
  display: flex;
  flex-direction: column;
}

.SA-dashGraph {
  width: 100%;
}

.SA-dashGraph1 {
  width: 100%;
}

.SA-profilelable1 {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  /* font-size: 20px; */
  line-height: 24px;
  color: #283B4C;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.Sa-dashDate {
  padding: 15px;
}

.Sa-leadsDiv {
  background:rgba(0,0,0,0.08);
  box-shadow: 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
  border-radius: 10px;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 30px;
  height: fit-content;


}

.Sa-totalLeads {
  border: 1px solid #FD6C73;
  box-sizing: border-box;
  border-radius: 8px;
  width: 40%;
  height: fit-content;

}

.Sa-totalLeads1 {
  border: 1px solid #FD6C73;
  box-sizing: border-box;
  border-radius: 8px;
  width: 20%;
  height: fit-content;

}

.SA-Percent1 {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */
  color: #000000;
  /* border: 1px solid magenta; */
  /* margin-left: 75px; */
}

.SA-DashboardLable1 {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  color: #707070;
  margin-top: 10px;
}

.SA-LeadssDiv {
  /* style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "25px" }} */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;

}

.dis-input {

  border: 0;
  background: #FFFFFF;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* line-height: 18px; */
  /* or 150% */
  padding: 1px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #334D6E;
}

.Dis-dashHeader {
  width: 101%;
  height: 69px;

  margin-left: 5px;
  background: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dis-branchname {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */
  width: auto;
  -webkit-appearance: auto;
  outline: none;
  border: none;
  color: #4274B0;
}

.dis-profilepercent {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 3px solid #59A7FF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  letter-spacing: 0.02em;
  color: #252733;
}

.dis-profile {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  letter-spacing: 0.04em;

  color: #252733;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.dis-headerbutton {
  width: 123px;
  height: 32px;
  left: 1283px;
  top: 81px;

  background: #FD6C73;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  letter-spacing: 0.04em;

  color: #FFFFFF;
  margin-left: 20px;
}

.headingVerified {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
  letter-spacing: 0.04em;
  color: #707070;
  justify-content: center;
}

.VreifiedHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #283B4C;
}

.VreifiedHeadingSmall {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #707070;
}

.VerfiedInputTag {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128.91%;
  color: #283B4C;
}

.dropdownVeri {
  border: none;
  border-bottom: 1px solid #E0E0E0;
  outline: 0;
  -webkit-appearance: auto;
}
.dropdownVeri_input{
  border: none;
  border-bottom: 1px solid #E0E0E0;
  outline: 0;

}

/* .dropdownVeri_input::-webkit-file-upload-button {
  display:none
} */

.ValueVerified {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 128.91%;
  /* identical to box height, or 17px */

  display: flex;
  align-items: center;
  letter-spacing: 0.04em;

  color: #BABABA;
}

.CancelButton {
  border-radius: 2px;
  height: 30px;
  border: 1px solid #939393;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.04em;
  cursor: pointer;
  color: #636363;
  padding: 5px 16px;
  background: #ffffff;
}

.SubmitButton {
  background: #4274B0;
  ;
  border-radius: 2px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  cursor: pointer;
  letter-spacing: 0.04em;
  margin-left: 20px;
  color: #FFFFFF;
  padding: 6px 16px;
}

.searchDivnew {
  width: '80%';
  height: '200px';
  background: "white";
  border: '1px solid grey';
  /* overflow-x: hidden;
  overflow-y: auto;
  text-align: justify */
}