.cstm_notify
{
    width:100%;
    height: 85vh;
    overflow-x: auto;
    overflow-y:auto;
    word-break: break-word;
    overflow-y: hidden;

 /* border:2px solid red; */
}

.cstm_notify input
{
    width: 300px;
    outline: 0;
    border: 0;
    background: inherit;
   border-bottom: 1px solid #E0E0E0;
}

.cstm_notify_inner
{

    min-height:550px;
    min-width: 900px;
    padding:20px;
    padding-left: 45px;
    /* border:1px solid black */
}

.custom_message_area
{
    min-height: 200px;
 /* height:30vh; */
 margin-bottom: 10px;
 border-radius: 5px;
 padding: 15px;
 background: #FFFFFF;
 /* border:1px solid black; */

}
.cn_msg_upper_section
{
    height:80%;
    /* border:1px solid black */

}
.cn_send_noti
{
height:75%;
display: flex;
/* border:1px solid green; */
}
.cn_noti_left
{
    width: 40%;
    height: 100%;
    /* border:1px solid black */
}
.cn_notileft_up
{
    /* font-size: 1vw;
    font-weight: bold;
    text-decoration: underline; */
    height: 30%;
    /* border:1px solid green; */
}

.cn_notileft_middle
{
    height: 30%;
    /* margin-bottom: 15px; */
    /* border:1px solid green; */
}

.cn_notileft_middle select
{
    width:75%;
    outline: 0;
    border: 0;
    
    background: inherit;
   
    border-bottom:2px solid black;
}

.cn_notileft_botttom
{
    height: 40%;
    /* border:1px solid red; */
}
.cn_notileft_botttom1
{
    font-size:0.9vw ;
    font-weight: bold;
  
    display: flex;
    justify-content: space-between;
    
    border-bottom:2px solid black;
}
.cn_notileft_botttom2
{
    color: gray;
    font-size: 8px;
}
.cn_noti_right
{
    padding-left: 40px;
    padding-right: 40px;
    width:60%;
    height: 100%;
    /* border:1px solid red; */
}

.cn_noti_right select
{
    outline: 0;
    border: 0;
    background: inherit;
    margin-bottom: 5px;
    border-bottom: 1px solid rgb(224, 224, 224);
}
.cn_right_dropdown1
{
    border:"0";
    outline:"0";
}
.cn_right_dropdown1 option
{
 border:0;
 outline: 0;
}

.cn_right_middle
{
    /* height: 55%; */
    z-index: 2;
    /* border:1px solid red; */
}
.cn_profile_type_list
{
    max-height: 400px;
    /* height: 300px; */
    width: 100%;
    height: 58vh;
    padding: 2px;
    overflow-y: auto;
    position: relative;
    overflow-x: hidden;
    z-index: 2;
    border-radius: 4px;
border:1px solid rgb(161, 154, 154);
}

.cn_profile_type_list_inner
{
    /* padding: 10px; */
    padding-left: 0;
    padding-right: 0;
    word-break: break-word;
    /* width: 90%; */
    position: absolute;
    width: 100%;
    /* height: 70vh; */
    background: white;
   
     /* border:1px solid red; */
}
.cn_list_search
{
    /* margin-top: 5px; */
    display: flex;
    justify-content: flex-end;

}

.cn_right_searchbox
{
    font-size: 12px;
    padding: 5px;
    height:60%;
    border:2px solid black;
}

.cn_right_searchbox input
{
    width: 100%;
    outline: 0;
    border: 0;
    background: inherit;
}
.cn_msg_enter_text
{
    height:25%;
    /* border:1px solid green; */
}
.cn_msg_enter_text_textlimit
{
    /* padding-left: 5px; */
    font-size: 8px;
    color: gray;
}
.cn_msg_lower_section
{
 
    padding: 7px;
    height:20%;
    /* border:1px solid black */
}
.cn_msg_lower_section button
{
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.cn_noti_history_table_area
{

    overflow-y:auto;
    /* border-radius: 8px; */
    min-height: 280px;
    height:50vh;

}


/* p */

.cn_table1 {
    font-family: Lato;
    font-size: 13px;
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
    border-radius: 5px;
  }
  
  .cn_table1 td, .cn_table1 th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .cn_table1 tr:nth-child(even){background-color: #ffffff;}
  .cn_table1 tr:nth-child(odd){background-color: #ffffff;}

  /* #cn_table1 tr td:first-child {
    width: 5em;
    min-width: 5em;
    max-width: 5em;
    word-break: break-all;
  } */
  
  .cn_table1 tr:hover {background-color: #ddd;}
  
  .cn_table1 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0097F9;
    color: white;
  }

/* p */

/* p */

.cn_table2 {
    font-family: Lato;
    font-size: 13px;
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
    border-radius: 5px;
  }
  
  .cn_table2 td, .cn_table2 th {
    /* border: 1px solid #ddd; */
    padding: 8px;
  }
  
  .cn_table2 tr:nth-child(even){background-color: #ffffff;}
  .cn_table2 tr:nth-child(odd){background-color: #ffffff;}

  /* #cn_table1 tr td:first-child {
    width: 5em;
    min-width: 5em;
    max-width: 5em;
    word-break: break-all;
  } */
  
  .cn_table2 tr:hover {background-color: #ddd;}
  
  .cn_table2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0097F9;
    color: white;
  }

/* p */


.cn_noti_heading
{
font-size: 1vw;
font-weight: bold;
text-decoration: underline;
margin-bottom: 10px;
/* border:1px solid green; */
}

.cn_coti_search
{
margin-bottom: 15px;
}

.cn_search_inner
{
    /* width:fit-content; */
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    border-radius: 10px;
    border:2px solid black;
}
.cn_search_inner input
{
    border:0;
    outline: 0;
    background: inherit;
}
.search_img_container
{
    display: flex;
    align-items: center;
    margin-right: 10px;
}


.cn_noti_table_container
{

}





@media screen and (max-width:900px)
 {
    .cn_noti_heading
    {
        font-size: 13px;
    }
    .cn_notileft_up
    {
       font-size: 13px;;
    }
    .cn_notileft_botttom1
    {
        font-size: 13px;
    }
    .cn_notileft_botttom2
    {
        font-size: 8px;
    }
}