.sa-mastersidebar{
    
    /* border:1px solid black; */
    width:210px;
    max-height: 100%;
    height: 89vh;
    overflow: auto;
    text-indent: 20px;
    background: #4d484a;
    padding: 10px;
    justify-content:space-evenly;
    position: fixed;
    margin-left: 4px;
    
   

}

.Sa-sidebar{
  
/* font-family: Lato; */
/* font-style: normal; */
/* font-weight: normal; */
/* font-size: 13px;
line-height: 35px; */
/* identical to box height, or 100% */

/* letter-spacing: 0.01em;

color: #FFFFFF; */
font-size: 13px;
font-weight: 400;
color: #f2ecf8;
line-height: 35px;
}

.Sa-sidebar2{
    font-size: 13px;
    font-weight: 400;
    color: #f2ecf8;
    line-height: 15px;
}

.sa-mastersidebar :hover{

    color:#e1dde6;
}