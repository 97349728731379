* {
    font-family: Lato;
}
body {
    overflow-x: hidden;
    background-color: #F5F5F9;
}
a {
    text-decoration: none;
    color: #4274B0;
    cursor: pointer;
}
h4 {
    font-weight: 500;
    color: #283B4C;
    font-size: 20px;
    line-height: 32px;
}
h4.blueblock {
    background: #E7F2FF;
    padding: 8px 20px;
    margin-bottom: 25px;
}
p {
    color: #75787A;
    font-size: 16px;
}
.dark {
    color: #283B4C;
}
.blue {
    color: #4274B0;
}
.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.blue-bg {
    background-color: #F4F9FF;
}
input.pac-target-input {
    outline: 0;
    border: 0px solid #CCC;
    font-size: 16px;
    background-color: #FFF0;
}
.rangeslider-horizontal {
    height: 5px;
    border-radius: 0;
    margin: 15px 9;
}
.rangeslider-horizontal .rangeslider__fill {
    background-color: #FD6C73;;
}
.rangeslider-horizontal .rangeslider__handle:after {
    display: none;
}
.rangeslider-horizontal .rangeslider__handle {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 1px solid #FD6C73;
    box-shadow: 0 1px 3px rgb(0 0 0 / 0%), 0 -1px 3px rgb(0 0 0 / 0%);
}

.react-multi-carousel-dot button {
    border: 2px solid #707070;
    width: 13px;
    height: 13px;
    border-radius: 6px;
    background-color: #FFF;
    margin: 0 10px
}
.react-multi-carousel-dot--active button {
    background-color: #FD6C73;
    border: 2px solid #FFF;
}
.react-multiple-carousel__arrow {
    background: rgb(255 255 255 / 50%);
}
.detail-slider .react-multiple-carousel__arrow {
    background: rgb(255 255 255 / 50%);
}
.whyus-div .react-multi-carousel-list {
    position: inherit;
}
.whyus-div .react-multiple-carousel__arrow {
    background-color: #FFF0;
}
.whyus-div .react-multiple-carousel__arrow::before, .whyus-div .react-multiple-carousel__arrow::after {
    font-size: 40px;
    font-weight: bold;
    color: #6B6B6B;
}
.react-multiple-carousel__arrow--right {
    right: 10px;
}
.react-multiple-carousel__arrow--left {
    left: 10px;
}
.progressWrapper {
    height: 4px;
    background-color: #C4C4C4;
    overflow: hidden;
}
.progressContainer {
    background-color: #C4C4C4;
}
.progressContainer > div {
    background-color: #4274B0 !important;
}
.progressLabel {
    display: none;
}


.home-banner-div {
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-header-div {
    width: calc( 100% - 0px );
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    background-color: #FFF;
}
.home-header-div img.avatar {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    margin-right: 12px;
}
.home-header-div h4 {
    font-size: 17px;
}
.home-logo-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 20px;
}
.home-logo {
    width: 140px;
    height: 45px;
}
.home-header-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.home-login-button-div {
    width: 220px;
    height: 50px;
    margin: 0 30px;
    border-radius: 4px;
    background-color: #4274B0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;  
    color: #FFF;
    font-size: 20px;
}
.home-search-container {
    height: 320px;
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 72px;
}
.home-search-container-inner {
    width: 100%;
    height: 100%;
    background-color: #0000006b;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-search-div {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.home-search-div .dropdown {
    position: absolute;
    background-color: #FFF;
    margin-left: 10px;
    margin-top: 12px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
}
.home-search-div .arrow {
    width: 14px;
    height: 14px;
    background-color: #FFF;
    transform: rotate(45deg);
    margin-top: -7px;
    margin-left: 18px;
}
.home-search-div .dropdown ul {
    list-style: none;
    padding: 0 4px;
    overflow-y: auto;
    height: 300px;
}
.home-search-div .dropdown li {
    padding: 12px;
    border-bottom: 0.5px solid #CCC;
    cursor: pointer;
    transition: all 0.5s;
}
.home-search-div .dropdown li:hover {
    color: #4274B0;
    font-weight: bold;
}
.home-search-div .collegedd li {
    padding: 4px 12px 0 12px;
}
.home-search-div .collegedd img {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    margin-right: 12px;
    margin-top: -4px;
}
.home-search-div .collegedd h4 {
    font-size: 18px;
    line-height: 24px;
}
.home-search-div .collegedd p {
    font-size: 14px;
    line-height: 20px;
    color: #666;
    padding-left: 0;
    font-weight: 500;
}
.home-search-div .dropdown li.active {
    color: #4274B0;
    font-weight: bold;
}
.home-search-inner {
    display: flex;
    flex: 9;
}
.home-search-input-div {
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    padding: 0 15px;
    margin-left: 10px;
    border-radius: 3px;
}
.home-search-div p {
    padding-left: 15px;
    font-size: 16px;
    color: #E0E0E0;
    margin-bottom: 8px;
}
.search-bordered {
    background-color: #F1F3F4;
    height: 44px;
    margin-right: 10px;
    margin-left: 0px;
}
.home-search-input {
    width: 100%;
    border: 0;
    outline: 0;
    
    font-size: 16px;
    color: #707070;
}
.search-bordered .home-search-input{
    background-color: #F1F3F4;
}
.home-search-result-div {
    position: absolute;
    width: 60%;
    top: 320px;
}
.home-search-result-div-inner {
    width: calc( 70% - 1px );
    background-color: #FFF;
    padding: 10px 0;
}





.home-padded-div {
    padding: 60px 15px 10px 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.home-skill-list-div {
    width: calc( 13.66% - 15px );
    float: left;
    border: 1px solid #FD6C73;
    border-radius: 10px;
    padding: 16px 1.5% 16px 1.5%;
    margin: 6px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 1s;
}
.home-skill-list-div .imgdiv {
    height: 44px;
    transition: all 1s;
}
.home-skill-list-div .imgdiv img {
    height: 44px;
    transition: all 1s;
}
.home-skill-list-div .imgdiv > img:nth-child(2) {
    display: none;
}
.home-skill-list-div h4{
    font-size: 20px;
    color: #283B4C;
    margin-top: 6px;
    font-weight: 400;
}
.home-skill-list-div p{
    font-size: 13px;
    line-height: 13px;
    color: #707070;
}
.home-skill-list-div:hover {
    background-color: #FFEFF0;
}
.home-skill-list-div:hover h4{
    color: #283B4C;
}
.home-skill-list-div:hover p{
    color: #283B4C;
}
.home-skill-list-div:hover .imgdiv > img:nth-child(1) {
    display: none;
}
.home-skill-list-div:hover .imgdiv > img:nth-child(2) {
    display: inline-block;
}

.home-padded-div-two {
    padding: 30px 20px 40px 20px;
}
.home-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.home-main-heading {
    
    font-weight: 500;
    color: #000;
    font-size: 24px;
    margin-left: 8px;
}
.home-view-more {
    padding: 7px 10px 7px 20px;
    background-color: #FFF0;
    border-radius: 15px;
    border: 0;
    color: #0976b7;
    font-weight: normal;
    font-size: 18px;
}
.home-institute-card {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    margin: 5px 10px 5px 10px;
    background-color: #FFF;
    border-radius: 4px;
    margin-bottom: 30px;
}
.home-institute-card img {
    height: 220px;
}
.home-institute-card div.divimg {
    height: 180px;
    width: 80%;
    padding: 20px 10%;
}
.home-institute-card div.divimg div {
    height: 180px;
    width: 100%;
}
.home-institute-card .tip, .listing-card-div .tip, .home-educator-card .tip {
    border-bottom-left-radius: 4px;
    background-color: #000000;
    height: 20px;
    width: 80px;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFF;
    font-size: 13px;
    right: 20px;
    font-weight: normal;
    letter-spacing: 0.04em;
    font-weight: 600;
}
.home-institute-card .tip, .home-educator-card .tip {
    position: absolute;
}
.home-institute-card .tip, .home-educator-card .tip {
    right: 10px;
}
.home-educator-card .tip {
    top: 5px;
    width: 104px;
    height: 26px;
    justify-content: center;
    font-size: 16px;
    right: 11px;
}
.home-institute-card .tip img, .listing-card-div .tip img {
    width: 7px;
    height: 10px;
    margin-right: 5px;
}
.listing-card-div .tip {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 4px;
}
.home-institute-card h4 {
    font-weight: 500;
    font-size: 20px;
    margin: 10px 10px 10px 10px;
    color: #4274B0;
}
.home-institute-card .home-instiute-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 10px;
}
.home-institute-card .home-instiute-location {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px 0;
}
.home-institute-card .home-instiute-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}
.home-institute-card .home-instiute-location img {
    width: 10px;
    height: 14px;
    margin-right: 6px;
}
.home-institute-card .home-instiute-location p {
    
    font-size: 16px;
    color: #707070;
}
.home-institute-card .home-instiute-details img {
    width: 10px;
    height: 10px;
    margin-right: 6px;
}
.home-institute-card .home-instiute-details p {
    letter-spacing: 0.04em;
    font-size: 13px;
    color: #000;
    font-weight: 600;
}
.home-institute-card .home-instiute-courses  {
    margin-right: 4px;
}
.home-institute-card .home-instiute-courses h5 {
    font-family: 'Open Sans';
    font-size: 14px;
    color: #666;
    font-weight: normal;
}
.home-institute-card .home-instiute-courses p {
    font-family: 'Open Sans';
    font-size: 10px;
    color: #0976b7;
}

.home-educator-card {
    margin: 4px 10px;
    border: 1px solid #EEE;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
    margin-bottom: 30px;
    border-radius: 4px;
}
.home-educator-card .content {
    padding: 20px;
    border-top: 1px solid #1D416B;
    background-color: #1D416B;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.home-educator-card .avatar-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
}

.home-educator-card .avatar-content .rate {
    float: right;
    border: 1px solid #3F7BB4;
    
    font-size: 16px;
    line-height: 20px;
    color: #707070;
    padding: 2px 4px 2px 12px;
    border-radius: 4px;
}
.home-educator-card .avatar-content img {
    width: 78px;
    height: 78px;
    border-radius: 39px;
    margin-bottom: 20px;
}
.home-educator-card .avatar-content h5 {
    
    font-size: 20px;
    color: #4274B0;
    font-weight: 500;
    text-align: center;
    line-height: 28px;
}
.home-educator-card .avatar-content p {
    
    font-size: 16px;
    color: #999;
    margin-bottom: 6px;
}
.home-educator-card h6 {
    font-family: 'Open Sans';
    font-size: 12px;
    text-align: right;
    margin: 5px 10px;
    color: #333;
}
.home-educator-card .content table {
    width: 100%;
}
.home-educator-card .content tr td {
    padding: 3px 0;
}

.home-educator-card .content img {
    width: 16px;
    height: 16px;
}
.home-educator-card .content table .attrName {
    
    font-size: 16px;
    color: #FFF;
}
.home-educator-card .content table .attrValue {
    
    font-size: 16px;
    color: #FFF;
}
.home-categories .category-section {
    width: calc( 25% - 20px );
    display: inline-block;
    margin: 0 10px;
    height: 430px;
    float: left;
}
.home-categories .category-section > div {
    width: calc( 100% - 60px );
    height: 100%;
    background-color: #0005;
    padding: 0 30px;
    display: flex;
    justify-content: flex-end;
    transition: all 1s;
    flex-direction: column;
}
.home-categories .category-section > div h2 {
    font-size: 32px;
    color: #FFF;
    font-weight: 500;
    margin-bottom: 0px;
}
.home-categories .category-section:hover > div {
    background-color: #000A;
}
.home-categories .category-section > div .det-div {
    height: 0;
    overflow: hidden;
    transition: all 2s;
    margin-bottom: 20px;
}
.home-categories .category-section:hover > div .det-div {
    height: auto;
}
.home-categories .category-section > div .border-div {
    width: 150px;
    height: 5px;
    background-color: #4274B0;
    margin-bottom: 20px;
}
.home-categories .category-section h3 {
    font-size: 22px;
    color: #FFF;
    font-weight: 500;
}
.home-blog .blog-section {
    width: calc( 25% - 24px );
    display: inline-block;
    margin: 0 10px;
    float: left;
    border: 1px solid #DDD;
    margin-bottom: 10px;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);
}
.home-blog .blog-section .blog-img {
    width: 100%;
    height: 220px;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.home-blog .blog-section .blog-img div {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: #4274B0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-right: 20px;
    margin-bottom: -16px;
    cursor: pointer;
}
.home-blog .blog-section .blog-content {
    padding: 20px 10px;
    background: #FFF;
}
.home-blog .blog-section .blog-content h4 {
    color: #4274B0;
    font-size: 24px;
    font-weight: normal;
}
.home-blog .blog-section .blog-content p {
    color: #707070;
    font-size: 16px;
    margin: 6px 0;
    text-align: justify;
}
.home-blog .blog-section .blog-content p.views {
    color: #283B4C;
    font-weight: bold;
}
.home-banner-img {
    width: 100%;
    height: 260px;
    margin: 20px 0;
}


.home-why-card {
    margin: 4px 10px;
    margin-bottom: 30px;
}
.whyus-idiv {
    padding: 0 50px;
}
.home-why-card .avatar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
    justify-content: center;
}
.home-why-card .avatar-content img {
    width: 160px;
    height: 160px;
    border-radius: 90px;
    border: 10px solid #F5F5F9;
}
.home-why-card .content-div {
    background-color: #FFF;
    margin-top: -80px;
    padding: 70px 0 30px 0;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
}
.home-why-card .content-div h5 {
    font-size: 20px;
    color: #4274B0;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: center;
}
.home-why-card .content-div p {
    font-size: 16px;
    color: #283B4C;
    text-align: center;
    font-weight: 400;
}
.home-why-card p.content {
    font-size: 16px;
    color: #707070;
    padding: 20px 30px 20px 30px;
    text-align: left;
    line-height: 24px;
}






/* ------------- Footer ---------------------*/
.footer {
    width: calc( 100% - 50px );
    display: flex;
    background-color: #1D416B;
    padding: 60px 25px 40px 25px;
}
.footer .sections {
    flex: 1;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
}

.footer .sections .logo-div {
    width: 330px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
}
.footer .sections .connect-div h4 {
    color: #FFF;
    margin-top: 26px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: normal;
}
.footer .sections .connect-div p {
    color: #FFF;
    
    font-size: 16px;
    margin-top: 5px;
}
.footer .sections .social-div {
    margin-top: 20px;
    margin-bottom: 30px;
}
.footer .sections .social-div img {
    width: 44px;
    height: 44px;
    margin-right: 10px;
}
.footer .sections .app-div {
    margin: 20px 0;
}
.footer .sections .app-div img {
    height: 48px;
    margin-right: 10px;
}
.footer .sections h3 {
    font-weight: 400;
    font-size: 24px;
    color: #FFF;
    margin-bottom: 14px;
}
.footer .sections p {
    
    font-size: 16px;
    color: #FFF;
    margin-bottom: 6px;
}

.footer .sections .inner-sections {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}
.footer .sections .inner-sections div {
    flex: 1
}


.react-multi-carousel-dot-list  {
    margin-top: 20px;
}






/*  ------------- Listing page --------------*/
.listing-headlist-container {
    width: 100%;
    overflow-x: auto;
    background-color: #FFF;
}
.listing-headlist {
    min-width: 950px;
    display: flex;
    flex-direction: row;
    margin-left: 30px;
}
.listing-headlist div.hlist {
    padding: 18px 12px;
    font-size: 20px;
    font-weight: 400;
    color: #283B4C;
    margin-right: 3px;
}
.listing-headlist div.active {
    padding: 18px 12px 15px 12px;
    color: #FD6C73;
    border-bottom: 3px solid #FD6C73;;
}

.listing-headlist div:hover {
    padding: 18px 12px 15px 12px;
    color: #FD6C73;
    border-bottom: 3px solid #FD6C73;;
}
.listing-header-div {
    width: 90%;
    padding: 0 5%;
    height: 90px;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.listing-header-div .logo-div {
    height: 45px;
    width: 150;
}
.listing-header-div .logo-div img {
    width: 100%;
    height: 100%;
}
.listing-header-div .login-button {
    height: 46px;
    width: 155px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid #CCC;
    background-color: #FFF;
    font-size: 18px;
    color: #707070;
    border-radius: 5px;
}
.listing-header-div .search-div {
    height: 50px;
    width: calc( 100% - 500px );
    box-shadow: 0 1px 2px 0 rgba(9, 118, 183, 0.5);
    border: 1px solid rgba(9, 118, 183, 0.5);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF;
}
.listing-header-div .search-div .location-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 3;
}
.listing-header-div .search-div .location-input div {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #8d8d8d;
    font-size: 18px;
}
.listing-header-div .search-div .separator {
    height: 70%;
    width: 1px;
    background-color: #999;
    margin: 0 20px;
}
.listing-header-div .search-div .search-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    flex: 7
}
.listing-header-div .search-div .search-input input{
    width: 86%;
    border: 0;
    outline: 0;
    height: 30px;
    font-size: 16px;
}

.listing-heading-title {
    padding: 20px 50px 10px 30px;
    background-color: #F6F6F6;
    color: #283B4C;
    font-size: 16px;
}
.listing-heading-title span {
    color: #6B6B6B;
}
.listing-content {
    display: flex;
    flex-direction: row;
    padding: 5px 40px;
    background-color: #f6f6f6;
}
.listing-content .filterButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    background-color: #FFF;
    font-size: 16px;
    color: #707070;
    padding: 12px 10px;
    margin-bottom: 10px;
    display: none;
    cursor: pointer;
}
.listing-content .listing-filters {
    flex: 2.5;
}
.listing-content .listing-content-div {
    flex: 8;
    margin-left: 30px;
}
.listing-filter-div {
    width: 100%;
}
.listing-filter-div .header {
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.listing-filter-div .header h3 {
    font-size: 20px;
    color: #283B4C;
    font-weight: 500;
}
.listing-filter-div .header p {
    font-size: 16px;
    color: #EF1111;
    padding-bottom: 4px;
    border-bottom: 1px solid #0005;
}

.listing-filter-div .filter-selected {
    padding: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.listing-filter-div .filter-selected div.filters {
    background-color: #ECECEC;
    padding: 4px 6px;
    margin-bottom: 6px;
    margin-right: 6px;
    border-radius: 5px;
}
.listing-filter-div .filter-selected div.filters div {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: #283B4C;
}
.listing-filter-div .filter-selected div.filters div img {
    width: 13px;
    height: 13px;
    margin: 2px 2px 2px 14px;
    display: inline-block;
    cursor: pointer;
}
.listing-filter-div .filter-box {
    padding: 16px 12px;
    border-bottom: 1px solid #DDD;
}
.listing-filter-div .filter-box h4 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #283B4C;
    font-weight: 400;
}
.listing-filter-div .filter-box .option-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.listing-filter-div .filter-box .option-div .onethird {
    width: 33%;
}
.listing-filter-div .filter-box .option-div .half {
    width: 50%;
}
.listing-filter-div .filter-box .option {
    font-size: 14px;
    letter-spacing: 0.04em;
    color: #333;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
}
.listing-filter-div .filter-box .option input {
    margin-right: 6px;
}
.listing-filter-div .filter-box p.more {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: #1F5CA2;
    margin-bottom: 8px;
    margin-top: 4px;
}
.listing-filter-div .filter-box .location-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0;
}
.listing-filter-div .filter-box .location-filter p {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    font-size: 15px;
    color: #626262;
}
.listing-filter-div .filter-box .markers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 10px 0;
    font-size: 13px;
    color: #666;
}

.listing-card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    margin-bottom: 15px;
}
.listing-card-heading .lheading {
    display: flex;
    align-items: center;
}
.listing-card-heading .lheading h3 {
    font-size: 20px;
    font-weight: 500;
    color: #000;
}
.listing-card-heading .lheading p {
    font-size: 16px;
    font-weight: normal;
    color: #283B4C;
    margin: 0px;
}
.listing-card-heading .lheading h2 {
    color: #0976b7;
    font-size: 26px;
}
.listing-card-heading .heading-relevance {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #283B4C;
}
.listing-card-heading .heading-relevance div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.listing-card-heading .listing-select select {
    height: 38px;
    border: 0.4px solid rgba(112, 112, 112, 0.38);
    border-radius: 5px;
    min-width: 100px;
    margin-left: 12px;
    color: #283B4C;
    padding: 0 4px;
}
.listing-card-div .card {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    margin-bottom: 15px;
    border-radius: 4px;
    background-color: #FFF;
}
.listing-card-div .card .info-div {
    display: flex;
    align-items: stretch;
    flex-direction: row;
}
.listing-card-div .card .info-div .school-img {
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.listing-card-div .card .info-div .edu-img {
    margin: 15px 0px 0 15px;
    display: flex;
}
.listing-card-div .card .info-div .edu-img > div {
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.listing-card-div .card .info-div .edu-img > div img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    border: 3px solid #0976b7;
    margin-top: 19px;
}
.listing-card-div .card .info-div .edu-img > div p {
    font-size: 13px;
    margin-top: 19px;
}
.listing-card-div .card .info-div .school-img p {
   position: absolute;
   margin-top: 100px;
   align-self: center;
}
.listing-card-div .card .info-div .school-img .established {
   text-align: center;
   padding: 10px 0 6px 0;
   font-size: 13px;
   color: #FFF;
   font-weight: 600;
   background-image: linear-gradient(#0000, #0009);
}
.listing-card-div .card .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 43px;
    background-color: #F4F9FF;
    border: 0.5px solid rgba(0, 0, 0, 0.18);
}
.listing-card-div .card .card-footer .fee-div {
    color: #3a3939; 
    font-size: 16px;
    width: 220px;
    text-align: center;
}

.listing-card-div .card .card-footer .enquire-div, .listing-card-div .card .card-footer .enquire-div div {
    display: flex;
    justify-content: space-between;
    align-items: center;
} 
.listing-card-div .card .card-footer .enquire-div .compare {
    color: #0976b7;
    margin-right: 20px;
} 
.listing-card-div .card .card-footer .enquire-div .compare input {
    margin-right: 10px;
} 
.listing-card-div .card .card-footer .enquire-div div img {
    width: 18px;
    height: 19px;
    margin-right: 5px;
}
.listing-card-div .card .card-footer .enquire-div .enquire {
    background-color: #0976b7;
    margin: 0 10px;
    padding: 6px 18px;
    color: #FFF;
    border-radius: 5px;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
} 
.listing-card-div .card .info-div .info-content  {
    flex: 1;
    padding: 8px 20px;
}

.listing-card-div .card .info-div .info-content .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.listing-card-div .card .info-div .info-content .title .title-div h2 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    color: #283B4C;
    margin-bottom: 4px;
}
.listing-card-div .card .info-div .info-content .title .title-div p {
    font-size: 16px;
    color: #0009;
    display: flex;
    align-items: center;
}
.listing-card-div .card .info-div .info-content .view-share {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
}

.listing-card-div .card .info-div .info-content .view-share div.view {
    display: flex;
    flex-direction: row;
    align-items: center; 
    font-size: 14px;
    font-weight: 400;
    color: #283B4C;
    padding: 0; 
    margin: 0 0 0 30px;
}
.listing-card-div .card .info-div .info-content .view-share div.share {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    font-size: 14px;
    font-weight: 400;
    color: #4274B0;
    background: #EBF4FF;
    border: 1px solid #4274B0;
    border-radius: 4px;
    padding: 6px 16px; 
    margin: 0 0 0 30px;
    box-shadow: 0 3px 6px 0 #4274B055;
}
.listing-card-div .card .info-div .info-content .details {
    font-size: 16px;
    margin: 18px 0;
    font-style: italic;
    color: #283B4C;
    font-weight: 300;
}
.listing-card-div .card .info-div .info-content .info-table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.listing-card-div .card .info-div .info-content .info-table .separator {
    width: 1px;
    background-color: #DDD;
}
.listing-card-div .card .info-div .info-content .info-table .info-tr p {
    font-size: 16px;
    color: #707070;
    margin-bottom: 10px;
}
.listing-card-div .card .info-div .info-content .info-table .info-tr h4 {
    font-size: 20px;
    font-weight: 500;
    color: #283B4C;
}
.listing-pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 60px;
}

.listing-pagination div {
    background-color: #FFF;
    border: 1px solid #DFE3E8;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;
    padding-bottom: 3px;
}
.listing-pagination div.active {
    color: #0A72B0;
    border: 1px solid #0A72B0;
}
.listing-pagination div.disabled {
    background-color: #919EAB;
    border: 1px solid #919EAB;
    color: #C4CDD5;
    cursor:not-allowed;
}







.detail-slider {
    width: 100%;
    height: 300px;
    margin-top: 12px;
}
.detail-slider .slides {
    width: 100%;
    height: 300px;
}
.detail-slider .slides .overlay {
    width: 100%;
    height: 300px;
    background-color: #0008;
}
.detail-slider-info {
    position: absolute;
    width: 100%;
    height: 300px;
    margin-top: -300px;
}
.detail-slider-info > div {
    width: calc( 100% - 60px );
    padding: 16px 30px;
    height: 268px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.detail-slider .detail-slider-info .established {
    font-size: 16px;
    color: #FFF;
}
.detail-slider .detail-slider-info div a {
    color: #FFF;
    font-size: 16px;
    padding: 10px 30px;
    background-color: #4274B0;
    border-radius: 4px;
    margin-left: 14px;
}
.details-title-section {
    width: calc( 100% - 60px );
    justify-content: space-between;
    padding: 20px 30px;
    align-items: flex-start;
}
.details-title-section .claim {
    flex: 1;
    width: 20%;
}
.details-title-section .claim a {
    color: #4274B0;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 4px;
    border-bottom: 1px solid #4274B0;
}
.details-title-section .title-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -70px;
    z-index: 100;
    width: 60%;
}
.details-title-section .title-main .img-div {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
    display: flex;
}
.details-title-section .title-main .img-div img {
    width: 56px;
    height: 56px;
}
.details-title-section .title-main h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #283B4C;
}
.details-title-section .title-main p.location {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #707070;
}
.details-title-section .title-main p.map {
    font-size: 16px;
    line-height: 24px;
    color: #4274B0;
}
.details-title-section .title-compare {
    width: 20%;
}
.details-title-section .title-compare > div {
    font-size: 15px;
    color: #626262;
    margin-left: 30px;
    justify-content: flex-end;
    margin-bottom: 30px;
}
.details-title-section .title-compare  div.view {
    margin-right: 20px;
}
.details-title-section .title-compare .detail-rating img {
    margin-left: 3px;
    height: 18px;
    width: 18px;
}
.details-title-section .title-compare .shortlist-div {
    margin-left: 17px;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background-color: #FFF;
    justify-content: center;
}
.detail-info-table {
    width: 100%;
}
.detail-info-table .detail-div {
    margin: 30px;
    align-items: stretch;
}
.detail-info-table .detail-div div.info-tr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
}
.detail-info-table .detail-div div.separator {
    width: 1px;
    background-color: #CCC;
}
.detail-info-table .detail-div div.info-tr div {
    margin-left: 12px;
}
.detail-info-table .detail-div div.info-tr img {
    width: 20px;
}
.detail-info-table .detail-div div.info-tr p {
    font-size: 16px;
    line-height: 24px;
    color: #656565;
}
.details-main-content {
    width: calc( 100% - 60px );
    justify-content: space-between;
    padding: 20px 30px;
    align-items: flex-start;
}
.details-main-content .details-sidebar {
    width: 25%;
    margin-right: 25px;
}
.details-main-content .details-sidebar > div {
    background-color: #EFEFF7;
    margin-bottom: 24px;
}
.details-main-content .details-sidebar > div.latest-news {
    background-color: #FFF;
}
.details-main-content .details-sidebar > div.latest-news .news {
    padding: 14px 0;
    border-bottom: 0.5px solid rgba(56, 56, 56, 0.16);
}
.details-main-content .details-sidebar > div.latest-news  h4 {
    padding: 8px 18px;
}
.details-main-content .details-sidebar > div.latest-news .news p {
    border-left: 5px solid #FD6C73;
    padding: 0px 13px;
}
.details-main-content .details-sidebar > div.latest-news .news a {
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #4274B0;
    display: block;
    text-align: right;
    padding: 0 13px;
}
.details-main-content .details-sidebar a.dsidebar{
    font-size: 16px;
    color: #283B4C;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.details-main-content .details-sidebar a.active {
    color: #FFF;
    background-color: #FD6C73;
}

.details-main-content .details-sidebar > div.recommended {
    background-color: #FFF;
    padding: 24px 14px 0 14px;
}
.details-main-content .details-sidebar > div.recommended >  div.recdiv {
    border-bottom: 1px solid rgba(0, 0, 0, 0.28);
    align-items: flex-start;
}
.details-main-content .details-sidebar > div.recommended div.recdiv img {
    width: 46px;
    height: 46px;
    border-radius: 28px;
    margin-right: 16px;
    margin-top: 12px;
}
.details-main-content .details-sidebar > div.recommended  div.recdiv h4 {
    font-size: 16px;
    line-height: 32px;
    color: #4274B0;
}
.details-main-content .details-sidebar > div.recommended  div.recdiv p {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #707070;
    opacity: 0.8;
}
.details-main-content .details-sidebar > div.recommended  div.recdiv button {
    width: 121px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    font-size: 16px;
    line-height: 19px;
    color: #283B4C;
    opacity: 0.8;
    margin: 14px 0;
}
.details-main-content .details-sidebar > div.recommended  div.more {
    justify-content: center;
    padding: 10px 0;
}
.details-main-content .details-sidebar > div.recommended  div.more h4 {
    color: #4274B0;
    margin-right: 8px;
}


.details-main-content .detail-content-div {
    width: calc(75% - 25px);
}
.detail-container {
    background-color: #FFF;
    border-radius: 6px;
    margin-bottom: 24px;
    padding: 20px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}
.detail-container h2 {
    color: #283B4C;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 16px;
}
.detail-container p {
    line-height: 24px;
}
.details-school-det {
    flex-wrap: wrap;
}
.details-school-det div {
    width: calc( 33.33% - 16px );
    margin-right: 20px;
    border: 0.5px solid rgba(0, 0, 0, 0.18);
    height: 156px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 20px;
}
.details-school-det div:nth-child(3n) {
    margin-right: 0;
}
.details-school-det div p {
    margin-top: 10px;
}
.details-school-img img {
    margin-right: 20px;
    width: calc(25% - 15px)
}
.details-school-img img:nth-child(4n) {
    margin-right: 0px;
}
.details-dmsg {
    margin-top: 14px;
}
.details-dmsg img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 20px;
}

.detail-container table{
    width: 100%;
    border-collapse:collapse;
}
.detail-container table thead{
    background-color: #E7F2FF;
}
.detail-container table th{
    text-align: left;
    height: 48px;
    padding-left: 35px;
    font-weight: 500;
    color: #283B4C;
    font-size: 20px;
}
.detail-container table tr{
    border-bottom: 1px solid #0003;
}
.detail-container table tr:nth-child(2n){
    background-color: #F4F9FF;
}
.detail-container table td{
    height: 48px;
    padding-left: 35px;
    color: #75787A;
    font-size: 16px;
}
.detail-container table td p{
    width: 130px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #283B4C;
    background-color: rgba(202, 212, 157, 0.2);
}
.detail-container table td p.success{
    background-color: rgba(145, 196, 153, 0.2);
}
.detail-container table td p.danger{
    background-color: rgba(255, 188, 125, 0.2);
}
.detail-container table td p.warning{
    background-color: rgba(244, 246, 235, 1);
}
.sheading-component {
    margin: 20px 0;
}
.sheading-component .separator {
    flex: 1;
    height: 1px;
    margin-right: 18px;
    background-color: rgba(0, 0, 0, 0.18);
}

.sheading-component .title {
    width: 120px;
    background: rgba(253, 108, 115, 0.2);
    border-radius: 4px;
    height: 24px;
    justify-content: center;
    font-size: 13px;
}
.details-teacher {
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.18);
    margin-bottom: 16px;
}
.detail-container select {
    height: 35px;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    margin-left: 20px;
    background-color: #FFF;
    padding: 0 10px
}
.details-teacher .imgavatar {
    width: 100px;
}
.details-teacher .tinfo {
    justify-content: space-between;
    padding: 11px 24px;
}
.details-teacher p.teacher-det { 
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    padding: 3px 24px;
}
.details-teacher a, .details-standard a, .details-alumni .alumni-links a.first, .details-management a, .details-admission a { 
    font-size: 16px;
    line-height: 19px;
    color: #4274B0;
    border-bottom: 1px solid #4274B0;
    padding-bottom: 2px;
}
.details-standard  {
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.18);
    margin-bottom: 16px;
    align-items: stretch;
}
.details-standard .stan-heading {
    width: 120px;
    justify-content: center;
}
.details-standard .tinfo {
    justify-content: space-between;
    padding: 11px 24px;
}
.details-standard .tinfo > div {
    align-items: flex-start;
}
.details-standard .tinfo .imgdiv{
    height: 30px;
    width: 30px;
    background-color: #FFF1F1;
    border-radius: 15px;
    justify-content: center;
    margin-right: 8px;
}
.details-standard .tinfo > div h4 span {
    background-color: #E8E8E8;
    width: 55px;
    height: 15px;
    border-radius: 2px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.02em;
    color: #707070;
    display: inline-block;
    text-align: center;
}
.details-facilities {
    flex-wrap: wrap;
}
.details-facilities div {
    width: calc( 20% - 16px );
    margin: 0 10px 0px 10px;
}
.details-facilities div:nth-child(5n) {
    margin-right: 0;
}
.details-facilities div:nth-child(5n+1) {
    margin-left: 0;
}
.details-facilities div .img-container {
    height: 95px;
    border: 0.5px solid rgba(0, 0, 0, 0.26);
    border-radius: 4px;
    justify-content: center;
    width: 100%;
}
.details-facilities div h4 {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 24px;
}
.details-facilities div p {
    text-align: center;
}
.details-facilities div p a {
    font-size: 16px;
    line-height: 19px;
    color: #4274B0;
    border-bottom: 1px solid #4274B0;
    padding-bottom: 2px;
    margin-bottom: 24px;
    font-weight: 600;
    margin-top: -4px;
    display: inline-block;
}
.details-scholar > div {
    align-items: flex-start;
    margin-bottom: 24px;
}
.details-scholar img {
    width: 50px;
    height: 50px;
    margin: 10px 24px 0 0;
}
.details-alumni > div {
    align-items: flex-start;
    margin-bottom: 28px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.26);
    padding-bottom: 28px;
}
.details-alumni > div:last-child {
    border-bottom: 0px solid rgba(0, 0, 0, 0.26);
}
.details-alumni img.alimg {
    margin: 6px 24px 0 0;
}
.details-alumni p.descr {
    margin-left: 64px;
    margin-top: 10px;
}
.details-alumni .alumni-links {
    justify-content: space-between;
    width: calc(100% - 64px);
    margin-left: 64px;
    margin-top: 20px;
}
.details-alumni .alumni-links a.second {
    color: #4274B0;
}
.details-alumni .alumni-links a.second img {
    margin-left: 10px;
}
.details-management > div {
    padding: 20px;
}
.details-management > div:nth-child(2n+1) {
    background-color: #F4F9FF;
}
.details-management img.comma {
    margin: 0px 14px 0 40px;
}
.details-admission a {
    display: inline-block;
    margin: 20px 0 20px 0;
}
.details-admission > div {
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 30px;
}
.details-admission > div div.content {
    flex: 1;
    text-align: center;
}
.details-admission > div div.separator {
    width: 1px;
    background-color: rgba(0, 0, 0, 0.12);
    margin: 0 10px;
}
.details-admission > div div h4 {
    text-align: center;
    margin-bottom: 8px;
}

.details-admission > div div.content > div {
    display: inline-block;
    padding-bottom: 20px;
}
.details-admission > div div.content > div > p {
    padding: 8px 0;
}
.details-admission > div div.content > div > p p {
    font-size: 20px;
    font-weight: 500;
}
.details-admission > div div.content >div > p p.dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FD6C73;
    margin-right: 19px;
}
.details-events > div {
    border: 0.5px solid rgba(0, 0, 0, 0.18);
    align-items: stretch;
    margin-bottom: 24px;
}
.details-events > div div.leftdiv {
    width: 180px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.details-events > div div.rightdiv {
    padding: 12px 14px;
    flex: 1;
}
.details-events > div div.rightdiv  h6, .details-events-sec > div  h6{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #283B4C;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 4px;
}
.details-events-sec {
    display: flex;
    flex-wrap: wrap;
}
.details-events-sec > div {
    width: calc(33% - 12px);
    margin-right: 20px;
    margin-bottom: 20px;
}
.details-events-sec > div:nth-child(3n) {
    margin-right: 0px;
}
.details-events-sec > div h3 {
    display: inline-block;
    background-color: #E7F2FF;
    padding: 8px 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    border-radius: 4px 4px 4px 0px;
}
.details-events-sec > div > div {
    display: flex;
    border: 0.5px solid rgba(0, 0, 0, 0.18);
    border-radius: 0px 4px 4px 4px;
    flex-direction: column;
    padding: 24px 20px;
}
.details-events-sec > div  h6 {
    margin: 10px 0;
}
.details-events-sec > div  p {
    margin: 10px 0;
}
.details-events-sec > div  div.click {
    margin-top: 10px;
    justify-content: flex-end;
}
.details-events-sec > div  div.click img {
    margin-left: 20px;
}
.details-events-sec > div  div.click a {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #4274B0;
}

.details-qualification > div {
    align-items: flex-start;
    margin-bottom: 28px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.26);
    padding-bottom: 28px;
}
.details-qualification > div div {
    align-items: flex-start;
}
.details-qualification > div div.detdiv {
    align-items: stretch;
    margin: 3px 0 6px 0;
}
.details-qualification > div div h6{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #283B4C;
}
.details-qualification > div div.separator{
    width: 1px;
    margin: 0 13px;
    background-color: rgba(0, 0, 0, 0.26);
}
.details-qualification > div img {
    width: 80px;
    height: 80px;
    margin: 6px 36px 0 0;
}
.details-qualification > div:last-child {
    border-bottom: 0px solid rgba(0, 0, 0, 0.26);
}
.details-experience  {
    align-items: stretch;
}
.details-experience .left-div {
    width: 75px;
    margin-right: 32px;
}
.details-experience .left-div h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #283B4C;
    width: 75px;
}
.details-experience .left-div p {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
}
.details-experience .middle-div {
    margin-right: 52px;
    align-items: center;
    flex-direction: column;
    display: flex;
}
.details-experience .middle-div .sline {
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.16);
}
.details-experience div.right-div div {
    align-items: stretch;
    margin: 4px 0 10px 0;
}
.details-experience div.right-div div h6{
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #283B4C;
    font-weight: 600;
}
.details-experience div.right-div{
    margin-right: 100px;
}
.details-experience div.right-div div.separator{
    width: 1px;
    margin: 0 13px;
    background-color: rgba(0, 0, 0, 0.26);
}
.details-experience div.right-div p {
    margin-bottom: 45px;
}
.details-reviews .rating-div  {
    align-items: stretch;
    border-bottom: 1px solid rgba(112, 112, 112, 0.38);
}
.details-reviews .rating-div .left-div  {
    width: 30%;
    border-right: 1px solid rgba(112, 112, 112, 0.38);
    margin: 20px 0;
    
}
.details-reviews .rating-div .left-div .rating  {
    width: 67px;
    height: 67px;
    justify-content: center;
    color: #FFF;
    margin-right: 16px;
}
.details-reviews .rating-div .left-div .basedon  {
    font-size: 13px;
    line-height: 16px;
}
.details-reviews .rating-div .left-div .all-ratings  {
    width: 80%;
}
.details-reviews .rating-div .left-div .all-ratings .ratings  {
    justify-content: space-between;
    margin-bottom: 5px;
}
.details-reviews .rating-div .left-div .all-ratings .ratings img {
    width: 11px;
    height: 11px;
}
.details-reviews .rating-div .left-div .all-ratings .ratings p {
    font-size: 12px;
    color: #283B4C;
    line-height: 18px;
}
.details-reviews .rating-div .left-div .all-ratings .ratings p:last-child {
    width: 20px
}
.details-reviews .rating-div .left-div .all-ratings .ratings .rating-percent  {
    width: 70%;
    height: 5px;
    background-color: #EFEFF0;
}
.details-reviews .rating-div .left-div .all-ratings .ratings .rating-percent > div {
    background-color: #FFC600;
    height: 4px;
}
.details-reviews .rating-div .right-div  {
    width: calc(70% - 40px);
    padding: 20px;
}
.details-reviews .rating-div .right-div p  {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #707070;
    margin-top: 20px;
}
.details-reviews .rating-div .right-div button {
    width: 141px;
    height: 36px;
    justify-content: center;
    background-color: #FFF;
    border: 0.666667px solid #0976B7;
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.04em;
    color: #0976B7;
    margin-top: 32px;
    cursor: pointer;
}
.details-reviews .review-div {
    margin-top: 20px;
}
.details-reviews .review-div .review {
    align-items: flex-start;
    border-bottom: 1px solid #EFEFF0;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.details-reviews .review-div .review:last-child {
    border-bottom: 0px solid #EFEFF0;
}
.details-reviews .review-div .avatar-div{
    margin-right: 24px;
}
.details-reviews .review-div .avatar-div img{
    width: 48px;
    height: 48px;
    border-radius: 24px;
}
.details-reviews .review-div .heading-div{
    justify-content: space-between;
}
.details-reviews .review-div .heading-div h4 div{
    width: 60px;
    height: 26px;
    background-color: #FFC600;
    justify-content: center;
    margin-left: 24px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}
.details-reviews .review-div .heading-div h4 div img{
    margin-left: 6px;
}
.details-reviews .review-div .heading-div .share{
    width: 36px;
    height: 36px;
    border: 1px solid #4274B0;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    justify-content: center;
}
.details-reviews .review-div .review-tags {
    margin: 10px 0;
}
.details-reviews .review-div .review-tags > div {
    display: inline-block;
    background: #F0F0F0;
    border-radius: 4px;
    padding: 0px 6px;
    font-size: 16px;
    line-height: 24px;
    color: #283B4C;
    margin: 0 10px 10px 0;
}
.details-reviews .review-div .review-tags > div span{
    font-size: 12px;
    line-height: 24px;
    margin-left: 8px;
}
.details-reviews .review-div .review-tags > div img {
    height: 10px;
    width: 10px;
}
.details-reviews .review-div h4.tagline {
    font-size: 24px;
line-height: 24px;
margin-bottom: 10px;
}
.details-reviews .review-div  .helpful-div {
    margin-top: 16px;
}
.details-reviews .review-div  .helpful-div p {
    font-size: 13px;
    line-height: 16px;
    color: #707070;
    margin-right: 26px;
}
.details-reviews .review-div  .helpful-div .helpful-img {
    width: 75px;
    height: 36px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    color: #707070;
    justify-content: center;
    margin-right: 12px;
    cursor: pointer;
}
.details-reviews .review-div  .helpful-div .helpful-img img {
    margin-right: 10px;
}



.signin-div {
    width: 70%;
    min-width: 700px;
    max-width: 900px;
    background-color: #FFF;
    align-items: stretch;
}
.signin-div > div {
    flex: 1;
    overflow-x: hidden;
}
.signin-div > div .signin-slider-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 400%;
    transition: all 1s;
}
.signin-div > div .signin-slider-div div {
    float: left;
}
.signin-slider {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.signin-slider img {
    height: auto;
    width: 100%;
}
.signin-slider div.sigin-slider-info h3 {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 42px;
    background: linear-gradient(to right, #78DDA6 0%, #17A8E5 50%, #175CE5 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.signin-slider:nth-child(2) div.sigin-slider-info h3 {
    background: linear-gradient(to right, #E2AC5B 0%, #CF80D0 0%, #3854BA 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.signin-slider:nth-child(3) div.sigin-slider-info h3 {
    background: linear-gradient(to right, #DD78A8 0%, #E59317 0%, #E517B8 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.signin-slider p {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #283B4C;
    padding: 5px 20px 10px 20px;
}
.signin-slider-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.signin-slider-dots > div {
    width: 6px;
    height: 6px;
    border-radius: 3px; 
    background-color: #C8CACC;
    margin: 0 4px;
    transform: all 1s;
}
.signin-slider-dots > div.active {
    width: 14px;
    background-color: #0976B7;
}



.signin-right-div {
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}
.signin-right-div .close {
    display: flex;
    justify-content: flex-end;
    height: 22px;
    flex: 0;
}
.signin-right-div .close img{
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-right: -13px;
}
.signin-right-div > div {
    flex: 1;
}
.signin-right-div > p {
    height: 24px;
    text-align: center;
    color: #283B4C;
}
.signin-right-div .signin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.signin-right-div .signin h2 {
    font-size: 44px;
    font-weight: 500;
    color: #283B4C;
}
.signin-right-div .signin p.loginp {
    font-size: 16px;
    line-height: 24px;
    color: #283B4C;
    font-weight: 300;
    margin-bottom: 15%;
    margin-top: 10px;
}
.signin-right-div input[type="text"],.signin-right-div input[type="number"], .signin-right-div select.institiute {
    border: 0;
    border-bottom: 1px solid rgba(106, 106, 106, 0.26);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 2px rgba(79, 161, 233, 0.1));
    padding: 10px 0;
    margin: 10px 0 20px 0;
    outline: 0;
    width: 100%;
}
.signin-right-div .otpinput > div {
    margin: 0 20px 0 0;
    align-items: center;
}
.signin-right-div .otpinput > div:last-child {
    margin: 0;
}
.signin-right-div .otpinput > div input[type="text"], .signin-right-div .otpinput > div input[type="number"] {
    text-align: center;
    margin: 10px 0 5px 0;
}
.signin-right-div  img.country {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.signin-right-div select.ccode {
    border: 0px solid rgba(106, 106, 106, 0.3);
    border-bottom: 0.5px solid rgba(106, 106, 106, 0.3);
    outline: 0;
    padding: 1px 15px 7px 15px;
    width: auto;
    margin-right: 10px;
    color: #707070;
    line-height: 20px;
    -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}
.signin-right-div div p.resend {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #4274B0;
    margin-bottom: 15%;
    cursor: pointer;
}
.signin-right-div button {
    background: linear-gradient(0deg, #1953E7 -22.83%, #30D3E0 118.48%);
    box-shadow: 4px 4px 8px rgba(33, 131, 228, 0.26);
    border-radius: 5px;
    border: 0;
    height: 48px;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.171429px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.signin-right-div button img {
    margin-left: 10px;
}
.signin-right-div .signin h4.inst {
    text-align: center;
    margin: 10px 0 10% 0;
}
.signin-right-div .signin h4.inst a {
    color: #283B4C;
}
.signin-right-div .signin div.loginProgress {
    width: 100%;
    text-align: center;
}
.signin-right-div .signin img.loginProgress img {
    width: 70%;
}
.signin-right-div .signin h4.inst a span {
    color: #4274B0;
}
.signin-right-div .signtype {
    width: 100%;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.signin-right-div .signtype > div {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 12px 0;
    font-size: 16px;
    line-height: 19px;
    color: #283B4C;
    cursor: pointer;
    border-bottom: 2px solid #0000;
}
.signin-right-div .signtype > div.active {
    border-bottom: 2px solid #FD6C73;
}
.signin-right-div div.separator {
    height: 3px;
    width: 80px;
    background-color: #FD6C73;
    margin: 12px 0;
}
.signin-right-div .inst-type-div > div {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #707070;
    padding: 6px 8px;
    border: 0.5px solid rgba(0, 0, 0, 0.38);
    border-radius: 24px;
    margin: 0 8px 8px 0;
}
.signin-right-div .inst-type-div > div.active {
    color: #4274B0;
    border: 0.5px solid #4274B0;
    background-color: #F4F9FF;
}

.location-div {
    padding: 40px 48px;
    max-height: 80vh;
    overflow-y: auto;
}
.location-div h2 {
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: #283B4C;
}
.location-div div.separator {
    height: 3px;
    width: 80px;
    background-color: #FD6C73;
    margin: 12px 0 40px 0;
}
.location-div .detect-location {
    cursor: pointer;
}
.location-div .detect-location img {
    margin: 0 10px 0 10px;
}
.location-div p.conly {
    margin: 0 10px 40px 0;
    font-size: 13px;
    line-height: 24px;
}
.location-div .edu-level {
    margin: 20px 0;
}
.location-div .search-parent {
    width: 60%;
}
.location-div .search-div {
    width: calc(100% - 80px);
    height: 48px;
    background-color: #F1F3F4;
}
.location-div .search-button {
    background-color: #4274B0;
    height: 48px;
    width: 48px;
    justify-content: center;
    border-radius: 4px;
    margin: 0 20px 0 8px;
}
.location-div .edu-level div {
    padding: 12px 40px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    display: inline-block;
    margin: 0 8px 8px 0;
    color: #707070;
    font-size: 16px;
    cursor: pointer;
}
.location-div .edu-level div.active {
    border: 1px solid #4274B0;
    color: #4274B0;
}
.location-div .standard-level div {
    padding: 9px 18px;
    border: 0.5px solid rgba(0, 0, 0, 0.38);
    border-radius: 24px;
    display: inline-block;
    margin: 0 10px 8px 0;
    color: #707070;
    font-size: 16px;
    cursor: pointer;
}
.location-div .standard-level div.active {
    background-color: #4274B0;
    border: 0.5px solid #4274B0;
    color: #FFF;
}



.feedback-div {
    width: 70%;
    min-width: 700px;
    max-width: 900px;
    background-color: #FFF;
    align-items: stretch;
    z-index: 1000;
}
.feedback-div .feedback-inner-div {
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.feedback-div .feedback-inner-div .close {
    display: flex;
    justify-content: space-between;
    flex: 0;
}
.feedback-div .feedback-inner-div img.teacher {
    width: 42px;
    height: 42px;
    border-radius: 21px;
    margin-right: 15px;
}
.feedback-div .feedback-inner-div .review-main {
    align-items: stretch;
}
.feedback-div .feedback-inner-div .left-div {
    flex: 1;
    border-right: 1px solid rgba(0, 0, 0, 0.18);
    padding: 20px 20px 20px 0;
}
.feedback-div .feedback-inner-div .left-div table {
    width: 100%;
}
.feedback-div .feedback-inner-div .left-div table tr td {
    color: #1E2228;
    font-size: 16px;
    line-height: 24px;
}
.feedback-div .feedback-inner-div .left-div table tr td:nth-child(3) {
    font-size: 16px;
    line-height: 24px;
    color: #707070;
}
.feedback-div .feedback-inner-div .left-div table tr td img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}
.feedback-div .feedback-inner-div .right-div {
    flex: 1;
    padding: 20px;
}
.feedback-div .feedback-inner-div .right-div {
    flex: 1;
    padding: 20px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}
.feedback-div .feedback-inner-div .right-div h4 {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    color: #283B4C;
}
.feedback-div .feedback-inner-div .right-div input {
    border: 0px solid #939599;
    border-bottom: 1px solid #939599;
    padding: 10px 0;
    font-size: 16px;
    line-height: 18px;
    color: #939599;
    margin-bottom: 30px;
}
.feedback-div .feedback-inner-div .right-div button {
    width: 155px;
    height: 40px;
    border: 0px solid #0000;
    color: #FFF;
    font-size: 16px;
    justify-content: center;
    background-color: #4274B0;
    border-radius: 4px;
}















.apply-div {
    padding: 10px 30px;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.apply-div .apply-school-div {
    width: calc(47% - 18px);
    margin-right: 18px;
    background-color: #FFF;
}
.apply-div .apply-form-div {
    width: 53%;
}
.apply-div .apply-school-div h1 {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    color: #283B4C;
    padding: 26px;
}
.apply-div .apply-school-div img {
    width: 100%;
}
.apply-div .apply-school-div .school-info {
    padding: 24px 0 36px 0;
}
.apply-div .apply-school-div .school-info > div {
    padding: 16px 24px;
    margin-bottom: 7px;
}
.apply-div .apply-school-div .school-info > div h4, .apply-div .apply-school-div .school-info > div p {
    flex: 1
}
.apply-div .apply-school-div .school-info > div p {
    flex: 1;
    text-align: right;
}
.apply-div .apply-header {
    background-color: #FFF;
    margin-bottom: 24px;
    padding: 17px 24px;
}
.apply-div .apply-header {
    background-color: #FFF;
    margin-bottom: 16px;
    padding: 17px 24px;
}
.apply-div .apply-header div.inner {
    flex: 1;
}
.apply-div .apply-header div.inner div p{
    flex: 1;
    height: 4px;
    background-color: #EBEBF2;
}
.apply-div .apply-header div.inner div p.hide{
    background-color: #EBEBF200;
}
.apply-div .apply-header div.inner div div.step{
    display: flex;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    justify-content: center;
    align-items: center;
    background-color: #EBEBF2;
    color: #707070;
}
.apply-div .apply-header div.inner h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #707070;
    text-align: center;
    margin: 5px 0 0 0;
    height: 48px;
}
.apply-div .apply-header div.active div p,.apply-div .apply-header div.active div div.step {
    background-color: #FD6C73;
    color: #FFF;
}
.apply-div .apply-header div.active h4{
    color: #283B4C;
}
.form-container {
    padding: 30px 20px;
    background-color: #FFF;
}
.form-group {
    display: flex;
    flex-direction: row;
}
.form-container input, .form-container select {
    outline: 0;
    border: 0px solid #000;
    border-bottom: 1px solid rgba(106, 106, 106, 0.26);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 2px rgba(79, 161, 233, 0.1));
    flex: 1;
    padding: 8px 2px;
    font-size: 15px;
    color: #707070;
    margin-bottom: 28px;
    margin-right: 20px;
}
.form-container .submit-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 16px 0;
    align-items: center;
}
.form-container .submit-div button {
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background: #4274B0;
    border-radius: 4px;
    padding: 12px 36px;
    border: 0px solid #000;
}
.form-container .submit-div p {
    color: #4274B0;
    padding: 12px 36px;
}
.form-container .form-upload-group {
    height: 60px;
    border: 1px dashed #4274B0;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0 16px 0 24px;
}
.form-container .form-upload-group > div {
    font-size: 16px;
    line-height: 24px;
    color: #4274B0;
    border: 1px solid #4274B0;
    padding: 6px 16px;
    filter: drop-shadow(1px 1px 2px rgba(66, 116, 176, 0.08));
    border-radius: 4px;
}
.form-container .upload-allow {
    font-size: 10px;
    line-height: 18px;
    color: #939599;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 4px 0 16px 0;
}
.form-container .upload-file-div  {
    margin-bottom: 10px;
}
.form-container .upload-file-div .upload-content {
    flex: 1;
}
.form-container .upload-file-div .file-type-img img {
    margin-right: 16px;
}
.form-container .upload-file-div .upload-content > div {
    justify-content: space-between;
    width: 100%;
}
.form-container .upload-file-div .upload-content > div:first-child {
    margin-bottom: 8px;
}
.form-container .upload-file-div .upload-content > div:nth-child(3) {
    margin-top: 8px;
}
.form-container .upload-file-div .upload-content > div:nth-child(3) p {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}
.compare-main-div {
    overflow-x: auto;
}
.compare-child-div {
    min-width: 1100px;
}
.compare-header {
    margin: 20px 30px;
    width: calc(100% - 60px);
    align-items: flex-start;
}
.compare-header > div {
    flex: 1;
    padding: 10px;
}
.compare-header > div div.imgdiv {
    width: 100%;
    height: 160px;
    margin-bottom: 16px;
}
.compare-blank .select-compare {
    border: 0.5px solid rgba(0, 0, 0, 0.38);
    box-sizing: border-box;
    border-radius: 4px;
    width: calc(100%);
    margin-top: 10px;
}
.compare-blank .select-compare .select-first {
    width: calc(31% - 1px);
    border-right: 0.5px solid rgba(0, 0, 0, 0.38);
    padding: 2%;
    background-color: #FFF;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.compare-blank .select-compare .select-first select, .compare-blank select {
    background-color: #0000;
    border: 0px solid #000;
    outline: 0;
    color: #707070;
    width: 100%;
}
.compare-blank select.sschool {
    background-color: #FFF;
    padding: 8px 4px;
    border-radius: 4px;
}
.compare-blank .select-compare input[type="text"] {
    background-color: #0000;
    border: 0px solid #000;
    outline: 0;
    color: #707070;
    padding: 2%;
}
.compare-blank p.or {
    text-align: center;
    width: 100%;
    padding: 12px 0;
}
.compare-content {
    margin: 20px 30px;
    width: calc(100% - 61px);
    border: 0.5px solid rgba(0, 0, 0, 0.18);
}
.compare-content > div {
    flex: 1;
    border-right: 0.5px solid rgba(0, 0, 0, 0.18);
    text-align: center;
}
.compare-content > div p {
    background-color: #F4F9FF;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #283B4C;
}
.compare-content > div:first-child p {
    font-weight: 400;
    font-size: 17px;
    color: #707070;
}
.compare-content > div p:nth-child(2n+1) {
    background-color: #E5E5E500;
}
.compare-content > div.active p:nth-child(2n+1) {
    background-color:#E7F2FF;
}
.compare-content > div:last-child {
    border-right: 0px solid rgba(0, 0, 0, 0.18);
}
.compare-content > div p.star div {
    background-color: #FFC600;
    display: inline-block;
    height: 26px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #FFF;
}
.compare-content > div p.facility div.fac {
    width: 30px;
    height: 30px;
    border-radius: 18px;
    border: 3px solid #FD6C73;
    background-color: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.compare-header .compare-apply {
    justify-content: space-between;
    margin-top: 20px;
}
.compare-header .compare-apply button{
    background: #4274B0;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 5px 30px;
    border: 0px solid #CCC;
}
.compare-header .compare-apply .shortlist-div {
    background: #FFFFFF;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 40px;
    height: 40px;
    justify-content: center;
    cursor: pointer;
}


.getVerifed {
    display: flex;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  
  