.backdrop_at_all_transparent
{
    position:fixed;
    height:100vh;
    width: 100%;
    /* background-color: rgba(0, 0, 0, 0.75); */
    z-index: 1;
    top:0;
    left: 0;

    /* border: 20px solid red; */
}