.loader_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0008;
    z-index: 1000;
  }
  
  .loader img {
    height: 150px;
  }
  