* {
    padding: 0;
    margin: 0;
}
.modal-container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
}