/* Online Admissions */
.middle-top-v {
    padding: 16px 16px;
  }
  
  .middle-top-v h1 {
    margin-bottom: 0px;
  }
  
  .p-16 {
    padding: 10px 16px;
    background: #f9f9f9;
    height: calc(100vh - 100px);
    overflow: auto;
  }
  
  .p-16-1 {
    padding: 16px 0px;
  }
  
  .p-16::-webkit-scrollbar,
  .p-16-2::-webkit-scrollbar {
    display: none;
  }
  
  .display-v {
    display: flex;
    justify-content: space-between;
  }
  
  .display-v.end {
    justify-content: flex-end;
  }
  
  /* //switch-tab */
  
  .nav-link {
    display: block;
  
  }
  
  .nav-v>li {
    display: inline;
  }
  
  .nav-link:hover,
  .nav-link,
  .nav-link:focus {
    background-color: transparent;
    font-weight: 600;
    color: #405877;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 16px;
  }
  
  .nav-link {
    display: inline;
  }
  
  .nav-item .active {
    background: #109CF1;
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    /* identical to box height */
    letter-spacing: 0.01em;
  }
  
  .nav-v {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 2px;
  }
  
  #one>div {
    background: #fff;
    padding: 16px;
    margin-top: 16px;
    border-radius: 5px;
    display: flex;
  }
  
  #one>div>div:first-child {
    width: 60%;
  }
  
  #one>div>div:last-child {
    margin-left: auto;
    margin-top: 16px;
    margin-right: 16px;
    display: flex;
  }
  
  #one>div>div:last-child.block {
    display: block;
  }
  
  .block>div {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
  
  .admission-input-v {
    height: 30px;
    background: #F5F5F5;
    font-weight: 400;
    width: 160px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    padding-left: 5px;
    color: #323C47;
  }
  
  #two .admission-input-v {
    background: #fff;
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 1;
    display: block;
    background: url(../../../assets/img/cal.svg) no-repeat;
    width: 16px;
    height: 16px;
    border-width: thin;
    padding: 2px 0px;
    padding-left: 10px;
    cursor: pointer;
    background-size: contain;
  }
  
  .ml-16-v {
    margin-left: 16px;
  }
  
  .ml-8 {
    margin-left: 8px;
  }
  
  .bold_settings {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
    letter-spacing: 0.01em;
    color: #323C47;
  }
  
  .black_settings-v {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 8px;
    letter-spacing: 0.01em;
    color: #323C47;
  }
  
  /* Toogle */
  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
  
    height: 12px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: #ccc;*/
    -webkit-transition: .4s;
    border: 1px solid #BDBABA;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: -6px;
    bottom: -4px;
    background-color: #BDBABA;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  
  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: #19C83F;
  }
  
  input:checked+.slider {
    border: 1px solid #BDBABA;
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .admission-link {
    color: #109CF1;
    font-size: 13px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .checkbox-v {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    margin: 0px -10px 0px 16px;
  }
  
  .checkbox-v__input input {
    opacity: 0;
    width: 0em;
    height: 0em;
  }
  
  .checkbox-v__control {
    display: inline-g-vrid;
    width: 0.5em;
    height: 0.5em;
    border-radius: 0.1em;
    border: 1px solid #BBB2B2;
  }
  
  .checkbox-v__input {
    display: grid;
    grid-template-areas: "checkbox";
  }
  
  .checkbox-v__control svg {
    color: #fff;
    background: #109CF1;
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    stroke-width: 4;
    padding: 2px;
    width: 0.5em;
    height: 0.5em;
    border: none;
    border-radius: 3px;
    transform-origin: bottom left;
  }
  
  .checkbox-v__input input:checked+.checkbox-v__control svg {
    transform: scale(1);
  }
  
  .checkbox-v__input input:checked+.checkbox-v__control {
    border: none;
  }
  
  .blue-button-v {
    background-color: #109CF1;
    color: #fff;
    padding: 0 16px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    line-height: 30px;
    cursor: pointer;
    height: 30px;
  }
  
  .white-button-v {
    color: #109CF1;
    background-color: #fff;
    padding: 0 16px;
    font-size: 12px;
    border: 1px solid #109CF1;
    border-radius: 5px;
    font-weight: 600;
    line-height: 28px;
    cursor: pointer;
    height: 30px;
  }
  
  .popup-razzor-pay {
    position: absolute;
    width: 700px;
    background-color: #fff;
    padding: 8px;
    display: none;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    right: 0px;
  }
  
  .tags {
    margin-top: 16px;
  }
  
  .tags>span {
    background: #DBF1FF;
    border-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 128.91%;
    padding: 4px 8px;
    margin-right: 16px;
    margin-bottom: 8px;
    letter-spacing: 0.01em;
    color: #000000;
    cursor: pointer;
    display: inline-block;
  }
  
  #two .search {
    background: url(../../../assets/img/search.svg) #fff no-repeat;
    background-position: 96% center;
    width: 300px;
  }
  
  .mt-16 {
    margin-top: 16px;
  }
  
  textarea.admission-input-v {
    height: 50px;
    width: 100%;
    padding-left: 8px;
  }
  
  .flex-v {
    display: flex;
  }
  
  .table_head,
  .table_head1 {
    background: #EFF0F5;
    border-radius: 2px;
    height: 50px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    letter-spacing: 0.01em;
    color: #323C47;
    align-items: center;
  }
  
  .table_head>div,
  .table_body>div {
    width: calc(32% / 2);
    padding-left: 8px;
  }
  
  .table_head>div.lg-div-v,
  .table_body>div.lg-div-v {
    width: 20%;
  }
  
  .table_head>div.sm-div-v,
  .table_body>div.sm-div-v {
    width: 7%;
    /* Total 8*3 */
  }
  
  .table_head>div.md-div-v,
  .table_body>div.md-div-v {
    width: 9%;
    /* Total 10*3 */
  }
  
  .table_body>div:last-child {
    text-align: right;
    padding-right: 10px;
  }
  
  .table_head>div:last-child {
    text-align: center;
  }
  
  .table_body,
  .table_body1 {
    padding: 10px 0px;
    display: flex;
    font-weight: 400;
    font-size: 12px;
    /* align-items: center; */
    line-height: 16px;
    letter-spacing: 0.01em;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #e0e0e2;
    color: #323C47;
  }
  
  .email-addjust {
    overflow-wrap: break-word;
  }
  
  .dropdown2 {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content2 {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    top: 0;
    right: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown-content2 a {
    color: #000;
    border-bottom: 1px solid #000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  
  .dropdown2:hover .dropdown-content2 {
    display: block;
  }
  
  .scrool1 {
    overflow-y: auto;
    height: calc(100vh - 265px);
  }
  
  .scrool1::-webkit-scrollbar,
  .scrool1-1::-webkit-scrollbar {
    width: 4px;
  }
  
  .table_body>div>label,.mob-res.table_body1>div>label {
    display: none;
  }
  
  .table_body>div a>img {
    margin-left: 8px;
    width: auto;
    height: 12px;
    cursor: pointer;
  }
  
  .blue_button-v {
    background-color: #109CF1;
    color: #fff;
    padding: 0 16px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    line-height: 30px;
    cursor: pointer;
    height: 30px;
  }
  
  .white_button-v {
    background-color: #fff;
    color: #109CF1;
    padding: 0 20px;
    font-size: 12px;
    line-height: 30px;
    border: 1px solid #109CF1;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    height: 30px;
  }
  
  h5.modal-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    margin: 0;
    display: inline-block;
    color: #334D6E;
  
  }
  
  #two .admission-input-v {
    margin-bottom: 16px;
  }
  
  .dropdown-content2.block-v {
    width: 700px;
    background-color: #fff;
    padding: 8px;
    left: 0px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  
  /* Process */
  .p-16-2 {
    padding: 10px 16px;
    background: #f9f9f9;
    min-height: calc(100vh - 100px);
    overflow: auto;
    margin-bottom: 40px;
  }
  .p-16-3 {
    padding: 10px 16px;
    background: #f9f9f9;
    min-height: calc(100vh - 144px);
    overflow: auto;
  }
  .p-16-2>p {
    background-color: #fff;
    padding: 8px;
    border-radius: 5px;
  }
  
  .progress_bar-v {
    display: flex;
    background-color: #fff;
    margin: 16px 0px;
    padding: 16px;
  }
  
  
  .progress_bar-v-outter>div:last-child {
    margin-left: auto;
    text-align: right;
  }
  
  .progress_bar-v-outter>div:last-child>p {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    /* identical to box height */
    margin-bottom: 0px;
    letter-spacing: 0.01em;
    margin-top: 24px;
    color: #636363;
  }
  
  .progress_bar-v>div>span {
    font-weight: 600;
    /*   font-size: 16px; */
    line-height: 22px;
    font-size: 15px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #636363;
  
  }
  
  
  .progress_bar-v>div>div {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .progress_bar-v>div>div>button {
    width: 20px;
    border-radius: 50%;
    height: 20px;
    background: #F3F2F2;
  }
  
  .progress_bar-v>div.selected-v>div>button {
    background: #D1EDFF;
    border: 2px solid #378FFB;
  }
  
  .progress_bar-v>div.selected-v>span {
    color: #109CF1;
  }
  
  .progress_bar-v>div>div>div {
    height: 2px;
    width: 150px;
    background: #F3F3F3;
  }
  
  .scrool1-1 {
    overflow-y: auto;
  /*   height: calc(100vh - 520px); */
    background-color: #fff;
    margin-bottom: 10px;
  }
  
  .table_head1>div,
  .table_body1>div {
    padding-left: 10px;
    width: 12%;
  }
  
  .table_head1>div:nth-child(2),
  .table_body1>div:nth-child(2) {
    width: 52%;
  }
  
  .table_head1>div.center-v:last-child,
  .table_body1>div.center-v:last-child {
    text-align: center;
    margin-left: auto;
  }
  
  a {
    cursor: pointer;
  }
  
  .p-16-2>p.bold-v {
    margin-bottom: 0px;
    font-weight: 700;
  }
  
  .scroll-solve-v::-webkit-scrollbar {
    display: none;
  }
  
  .modal .admission-input-v {
    width: 100%;
  }
  
  #AddModal.modal label {
    font-size: 13px;
    font-weight: 600;
    color: #323C47;
    margin-top: 8px;
  }
  
  #AddModal .modal-dialog {
    width: 350px;
  }
  
  .acceptbutton {
    padding: 0px 8px;
    background-color: #fff;
    font-weight: 600;
    font-size: 10px;
    line-height: 25px;
    /* identical to box height */
    border: 1px solid #B414ED;
    box-sizing: border-box;
    border-radius: 10px;
    letter-spacing: 0.01em;
    color: #B414ED;
  }
  
  .rejectbutton {
    padding: 0px 8px;
    background-color: #fff;
    border: 1px solid #EC1515;
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: 600;
    font-size: 10px;
    line-height: 25px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #EC1515;
  
  }
  
  .viewbutton {
    font-weight: 600;
    font-size: 10px;
    /* identical to box height */
    padding: 0px 8px;
    background-color: #fff;
    letter-spacing: 0.01em;
    border: 1px solid #1A81D4;
    box-sizing: border-box;
    border-radius: 10px;
    color: #1A81D4;
    line-height: 25px;
  }
  
   .process-edit-v {
    padding: 0px 8px;
    font-size: 11px;
    width: 95% ;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-weight: 600;
    color: #4a627f;
    height: 30px;
  
  }
  .process-edit-v::placeholder{
    color: #8e939a;
  }
  .progress_bar-v .process-edit-v {
    width: 150px;
  }
  textarea.edit-textbox {
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    height: 85px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-indent: 9px;
    width: 100%;
    padding: 10px 0px;
    color: #323C47;
  }
  
  .editbox-v {
    box-shadow: 2px 0px 10px 2px rgba(0, 0, 0, 0.1);
    background: #FFFFFF;
    display: flex;
    height: 25px;
    justify-content: space-around;
    padding: 2px 8px;
  }
  
  .editbox-v>div:nth-child(1) img {
    cursor: pointer;
    height: 11px;
    max-width: initial;
    width: auto;
  }
  
  .editbox-v>div:nth-child(1) {
    width: 10%;
  }
  
  #modal8 .editbox-v .line {
    border-right: 2px solid #F3F3F6;
    width: 2px;
  }
  
  .editbox-v select {
    border: none;
    padding: 0px 15px;
    cursor: pointer;
    outline: none;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.01em;
  
    color: #1A2334;
  }
  
  .editbox-v select .sans {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.01em;
  
    color: #1A2334;
    font-family: Open Sans;
  
  
  }
  
  .editbox-v select.tt {
    background: url(../../../assets/img/tt.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 27%;
  }
  
  .editbox-v select.font {
    background: url(../../../assets/img/font.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 28%;
    padding-left: 0;
    padding-right: 16px;
  
  }
  
  .line {
    background: #C4C4C4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    width: 1px;
    height: 21px;
  }
  
  .editbox-v div.fonts-v {
    display: flex;
    align-items: center;
  }
  
  .editbox-v div.fonts-v img {
    margin-right: 16px;
  }
  
  .editbox-v div.fonts-v img:first-of-type {
    margin-left: 16px;
  }
  
  .editbox-v div.fonts-v img.underline-v {
    width: 10px;
    height: auto;
  }
  
  .editbox-v div.color-v img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 0px 16px;
  }
  
  .editbox-v div.color-v img {
    position: relative;
    top: 3px;
  }
  
  .editbox-v div.font_align img {
    margin-right: 16px;
    height: 11px;
    width: auto;
  }
  
  .editbox-v div.font_align {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 16px;
  }
  .bottom-button-v{
    position: fixed;
    bottom: 0px;
  width: calc(100% - 14.5%);
  /* Subtract width of sidebar */
  }
  .bottom-button-v>div{
    background-color: #f9f9f9;
    padding: 16px;
    width:100%;
  }
  @media (max-width: 850px) {
    #one>div>div:first-child {
      width: 85%;
    }
  
    #one>div>div:last-child {
      margin-right: 0px;
  
    }
  
    #one>div {
      flex-wrap: wrap;
    }
  
    .admission-input-v {
      width: calc(50% - 8px);
    }
  
    #one>div>div:last-child.block {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
    }
  
    .popup-razzor-pay {
      width: 85vw;
    }
  
    #two .display-v,
    #two .display-v .flex-v {
      flex-wrap: wrap;
      width: 100%;
    }
  
    #two .search {
      width: 100%;
    }
  
    #two .admission-input-v:nth-child(3) {
      margin-left: 0px;
    }
  
  
    .table_head,.mob-res.table_head1>div:not(:last-child){
      display: none;
    }
    .table_body,.mob-res.table_body1 {
      display: block;
    }
  
    .table_body>div:nth-child(n),.mob-res.table_body1>div:nth-child(n) {
      width: 100%;
      display: flex;
      margin-bottom: 8px;
    }
  
    .table_body>div>label,.mob-res.table_body1>div>label {
      display: initial;
      width: 35%;
      font-weight: 600;
      text-align: initial;
    }
  .mob-res.table_body1>div>input{
      width: 65%;
    }
  
    .table_body>div>span {
      width: 65%;
    }
  
    .dropdown-content2 {
      right: initial;
      left: 30%;
    }
  
    .table_body1>div {
      padding-left: 10px;
      width: calc(70% / 4);
    }
  
    .table_head1>div:nth-child(2),
    .table_body1>div:nth-child(2) {
      width: 30%;
    }
  
    .scroll-solve-v {
      width: 100%;
      overflow-y: scroll;
    }
  
    .dropdown-content2.block-v {
      width: calc(100% - 20px);
      position: fixed;
      top: 20%;
      margin: 0px 10px;
    }
  
    .scrool1-1 {
      height: 250px;
    }
  
    #AddModal .modal-dialog {
      width: calc(100% - 20px);
    }
  
    .table_body1.w-40-v>div:nth-child(2),
    .table_head1.w-40-v>div:nth-child(2) {
      width: 60%;
    }
  
    .table_body1.w-80-v>div:nth-child(2),
    .table_head1.w-80-v>div:nth-child(2) {
      width: 80%;
    }
    .bottom-button-v{
      width: 100%;
    }
  }
  
  .backdrop_at_all_transparent
{
    position:fixed;
    height:100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
    top:0;
    left: 0;

    /* border: 20px solid red; */
}

.Heading-v{
  font: bold 14px/20px 'Open Sans', sans-serif;
    letter-spacing: normal;
    margin: 0;
}