.entire_dash_container
{
    /* background-color: #fff; */
    width:100%;
    /* border:2px solid red; */
    word-break: break-word;
    font-size: 1vw;
}

.sa_dash_inner_container
{
    width: 100%;
    padding:15px;
    padding-right: 25px;
    padding-left: 25px;
    /* border:2px solid red; */
}

.labels_tree_distribution_section
{
    width: 100%;
    height:28vh;
    display: flex;
    font-weight: bold;
    /* border:2px solid green; */
}
.labels_left_section
{
    width:33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border:1px solid red; */
}
.labels_div
{
    height:25%;
    width: 100%;
    display: flex;
    justify-content: center;
    /* border-radius: 8px; */
    /* border:1px solid green; */

}
.labels_div div
{
width: 90%;
height: 100%;
border-radius: 8px;
background-color: #FFFFFF;
}

.labels_tree_distribution_section p
{
    color: #0097F9;
}



.labels_left_section_div_middle
{
    height:25%;
    display:flex;
    justify-content: space-evenly;
    /* border:1px solid red; */
}

.labels_left_section_div_middle div
{
    height:100%;
    width:41%;
    border-radius: 8px;
    background-color: #FFFFFF;
    /* border:2px solid black; */

}


.labels_middle_section
{
    width:33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border:1px solid green; */
}


.labels_right_section
{   
     width:33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border:1px solid red; */
}

.dash_graph_and_map_section
{
    height:57vh;
     overflow-y: auto;
     /* scroll-behavior: none; */
    /* border:2px solid red; */
}

.dash_graph_and_map_section::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.sa_dash_graph_section
{
    /* height: auto; */
    width: 100%;
    height:60vh;
    /* min-height: 400px; */
    /* border: 2px solid blue; */
}

.dash_graph_margin
{
    height:7%;
    min-height: 20px;
    /* border:1px solid green; */
}
.graph_filters
{
    height:7%;
    min-height: 20px;
    display: flex;
    justify-content: space-between;
    /* border:1px solid green; */
}
.dashboard_graph_heading
{
font-weight: bold;
/* border:1px solid green; */
}
.dash_graph_two_filters
{
    width: 35%;
    height: fit-content;
    display: flex;
    justify-content: space-evenly;

    /* border:1px solid green; */

}
.dash_graph
{
    height:80%;
    width:100%;
    overflow-y: auto;
    overflow-x: auto;
    /* border:1px solid green; */
}

.sa_dash_map
{
    width: 100%;
    height:38vh;
    font-weight: bold;
    overflow-x: auto;
    overflow-y:auto;
    /* border:2px solid green; */
}

.dash_map_inner
{
    min-height: 230px;
    min-width: 900px;
    padding:10px;
    height: 100%;
    display: flex;
      /* border:1px solid blue; */
}

.map_div_left_section
{
    width:40%;
    /* border:1px solid red; */
}
.map_div_left_section p
{
    height:85%;
    /* border:1px solid black; */
}

.map_div_right_section
{
    width:60%;
    /* border:1px solid black; */
}

.maps_dropdown
{
    /* right:0; */
    display: flex;
    /* width: fit-content; */
    height: fit-content;
    /* right: 0; */
    /* width: 100%; */
    justify-content: flex-end;
    /* border:5px solid brown; */
}

.maps_information_data_section
{
    font-size: 0.9vw;
    height: 85%;
    min-height: 200px;
    padding:25px;
    /* border:5px solid blue; */
}
.maps_city_wise_data
{
    height: 60%;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid blue; */
}